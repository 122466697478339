import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    CForm,
    CAlert,
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButton
} from "@coreui/react";
import 'react-phone-input-2/lib/style.css'
import "react-datepicker/dist/react-datepicker.css";
import { STORE } from "../../helpers/CommonHelper";
import { callScheduled, expertCallScheduled } from "../../actions/ExpertActions";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import store from "../../store";
import { updateExpertStatus } from "../../actions/UserActions";


const ExpertCall = props => {
    const {data} = props
    const [error, setError] = useState("")
    const client = props.client != undefined ? true : false
    const sendDataToGTM = useGTMDispatch()

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            if(e.data.event == "calendly.event_scheduled"){
                // UPDATE USER STATUS
                var uriSplit = e.data.payload.invitee.uri.split("/")
                var callFunc = client ? callScheduled : expertCallScheduled                      
                STORE.dispatch(callFunc(
                    {...props.reqData,
                    ...{
                        event_id: uriSplit[uriSplit.length - 1],
                        request_type: "simple"
                    }
                    }
                    )).then(() => {
                    // MOVE TO NEXT STEP
                    store.dispatch(updateExpertStatus({status: "CALL_SCHEDULED"}))
                    sendDataToGTM({ event: 'simpleQuote', value: true })
                    props.setCalendlyEvent(uriSplit[uriSplit.length - 1])
                    props.updateStep(props.step+1)
                }).catch(({message}) => {
                    setError(message)
                })
            }
        }
      });

    useEffect(() => {
        // window.addEventListener(
        //     'message',
        //     (e) => {
        //         if (isCalendlyEvent(e)) {
                    
        //         }
        //     }
        // );
        // const head = document.querySelector('head');
        // const script = document.createElement('script');
        // script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        // head.appendChild(script);
    }, [])

    const isCalendlyEvent =(e) => {
        return e.data.event &&
               e.data.event.indexOf('calendly') === 0;
    };

    const getName = () => {
        if(data?.first_name && data?.last_name){
            return data?.first_name+" "+data?.last_name
        }
        return null
    }

    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >{client ? "3" : "4"}</p>
                    </div>
                    <p className="heading whiteText"> {client ? "Schedule a Call" : "Schedule an Interview"}</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                <CRow className="mb-5">
                    <CCol>
                        <CRow>
                            <CCol xs="12">
                                <p className="heading mb-3 bold">Call Calender</p>
                                <div className="ml-4" >
                                    <p className="lead" >
                                        {props.reSched ? (
                                            "We’ve been unable to meet at our initially scheduled time. Let’s fix that!. Please re-schedule a short call with us below."
                                        ) : (
                                            "We’ll review your information. In the meanwhile, please schedule a short call with one of our program managers below. Together we’ll go over the information, gather a little more infomation in person and see how we can best match you with clients and projects."
                                        )}
                                    </p>
                                    <p className="lead" >The calendar below displays available times in your time zone to make it as easy as possible. Scroll through the available day options using the left + right arrows. Click a day that works best for you to see the exact times that are available.</p>
                                    <p className="lead" >
                                        When you see a time that works best for you, click it, then click “Confirm.” On the next screen, fill in your contact details + click “Schedule Event.” You’ll be able to add the time to your own calendar using the iCal, Outlook + Google links or through the confirmation email that you’ll receive.
                                    </p>
                                    <p className="bold" >We look forward to talking to you and matching you with great clients!</p>
                                </div>
                                
                            </CCol>
                                <CCol className="mt-4" >
                                    {<CForm autoComplete="off" >
                                        {error && <CAlert closeButton color="danger">
                                            {error}
                                        </CAlert>}
                                        <CRow>
                                            <CCol xs="12" md="6" style={{margin: "auto"}} >
                                                <InlineWidget url="https://calendly.com/supplino-michelle/15-minute-meeting-experts"
                                                prefill={{
                                                    email: data?.email,
                                                    name: getName()
                                                  }}
                                                  />
                                                {/* <div
                                                    className="calendly-inline-widget"
                                                    data-url= {props.reSched ? "https://calendly.com/reschedulings/"+props.calendlyEvent : "https://calendly.com/supplino-michelle/15-minute-meeting-experts"}
                                                    // data-url= {props.reSched ? "https://calendly.com/reschedulings/"+props.calendlyEvent : "https://calendly.com/farazirfan/15min"}
                                                    style={{ minWidth: '320px', height: '580px' }} >
                                                </div> */}
                                            </CCol>
                                        </CRow>
                                        <CRow className="mt-4">
                                            <CCol className="text-right">
                                                <CButton color="secondary" className="btn100 btn-pill bold" onClick={() => props.updateStep(props.step-1)}>Back</CButton>
                                                {/* <CButton color="primary" className="btn200 btn-pill" onClick={handleSubmit(onSubmit)} disabled={updateLoader}>{updateLoader ? "Saving" : "Submit for review"}</CButton> */}
                                            </CCol>
                                        </CRow>
                                    </CForm>}
                                </CCol>
                        </CRow>
                    
                    </CCol>
                </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

export default withRouter(ExpertCall);
