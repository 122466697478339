import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CRow,
    CCard,
    CCardBody,
    CButton
} from "@coreui/react";

const ExpertJoinIndex = props => {

    useEffect(() => {
    }, []);

    return (
        <>
            <CCard className="mt-5 pl-4 pr-4" >
                <CCardBody >
                    <p className="heading bold mb-5" >Join our Network in four easy steps</p>
                    <CRow  >
                        <CCol>
                            <div style={{display: "flex", alignItems: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                <p className="mb-0 heading bold" style={{color: "#fff"}} >1</p>
                                </div>
                                <div className="subHeading" >Your Profile</div>
                            </div>
                        </CCol>
                        <CCol>
                        <div style={{display: "flex", alignItems: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                <p className="mb-0 heading bold" style={{color: "#fff"}} >2</p>
                                </div>
                                <div className="subHeading" >Expertise</div>
                            </div>
                        </CCol>
                        <CCol>
                        <div style={{display: "flex", alignItems: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                    <p className="mb-0 heading bold" style={{color: "#fff"}} >3</p>
                                </div>
                                <div className="subHeading" >Preferences</div>
                            </div>
                        </CCol>
                        <CCol>
                        <div style={{display: "flex", alignItems: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                    <p className="mb-0 heading bold" style={{color: "#fff"}} >4</p>
                                </div>
                                <div className="subHeading" >Call</div>
                            </div>
                        </CCol>
                    </CRow>
                    <div className="mt-5" style={{display: "flex", flexDirection: "column"}}  >
                        <p className="lead mb-1" >You can apply to our network in four simple steps by filling out this application. Please be thorough as we will use it to evaluate the best project fit and propose you interesting projects.</p>
                        <p className="lead" >Note: You control your information, and your profile will be shared only once you explicitly agree through our pitching process</p>
                    </div>
                    
                    <div className="text-center" >
                        <CButton onClick={() => props.updateStep(1)} className="mt-5 mb-5 btn-pill bold btn200" color="primary">
                            Get Started
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </>
    );
};

export default (withRouter(ExpertJoinIndex));
