import React from 'react'
import { CContainer } from '@coreui/react'
import Logo from "../../assets/brand/supplino_logo_white.png"
import CIcon from '@coreui/icons-react'

const ExpertFooter = () => {
  return (

    <div style={{background: "#000F5F"}} >
      <CContainer>
       
        <footer>
            <div class='container-fluid no-padding'>
              <div class='row'>
                <div class='col-xs-12 col-sm-12 col-md-6 col-lg-4 padding'>
                  <img class='logo' width='160px' src={Logo} alt="buildit.studio logo"></img>
                  <p className='mb-3' >Supplino’s marketplace unifies all the
                  steps of product design, prototyping, supplier 
                  management, and delivery processes.
                  </p>
                  <h4>Follow us on</h4>
                  <div class = "social">
                  <a target="_blank" href="https://www.linkedin.com/showcase/buildit.studio/"></a>
                    <a target="_blank" href="https://www.facebook.com/ProductCompletion"><CIcon className="social-item" name="cib-linkedin-in"/></a>
                    <a target="_blank" href="https://twitter.com/buildit_studio"><CIcon className="social-item" name="cib-twitter"/></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCo171oG3qnQp_p3niWUdawQ"><CIcon className="social-item"  name="cib-youtube"/></a>
                    <a target="_blank" href="https://www.instagram.com/buildit.studio/"><CIcon className="social-item"  name="cib-instagram"/></a>
                    <a target="_blank" href="https://medium.com/the-startup-supply-chain"><CIcon className="social-item" name="cib-medium-m"/></a>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg padding">
                  <h4>Getting Started</h4>
                  <ul>
                    <li><a target="_blank" href="/client/findExperts">Find an Expert</a></li>
                    <li><a target="_blank" href="/client/about">Get Product Support</a></li>
                    <li><a target="_blank" href="https://supplino.com/join-us-supply-chain-experts/">Join our Professional Network</a></li>
                    <li><a target="_blank" href="https://supplino.com/contact-us-supplino/">Contact Us</a></li>
                  </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg padding">
                  <h4>Company</h4>
                  <ul>
                    <li><a target="_blank" href="https://supplino.breezy.hr">Careers</a></li>
                    <li><a target="_blank" href="https://supplino.com/supply-chain-partners/">Partners</a></li>
                    <li><a target="_blank" href="https://supplino.com/investor-overview/">Investors</a></li>
                    <li><a target="_blank" href="https://supplino.com/about-supplino-we-love-supply/">About Supplino, Inc.</a></li>
                    <li><a target="_blank" href="https://supplino.com/stage/privacy-policy/">Privacy Policy</a></li>
                    <li><a target="_blank" href="https://supplino.com/stage/terms-and-conditions/">Terms of Use</a></li>
                  </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg padding">
                  <h4>About</h4>
                  <ul>
                    <li><a target="_blank" href="https://www.facebook.com/groups/4458323980874235">Product Entrepreneurs (on Facebook)</a></li>
                    <li><a target="_blank" href="https://medium.com/the-startup-supply-chain">Medium</a></li>
                    <li><a target="_blank" href="https://supplino.com/blog/">Our Blog</a></li>
                    <li><a target="_blank" href="https://builditstudio.statuspage.io/">System Status</a></li>
                  </ul>
                  {/* <h4><a target="_blank" href="https://buildit.studio/launch/tell-a-friend">Tell a Friend</a></h4> */}
                </div>
              </div>  
              <div class="row col-12 no-padding">
                <hr class="gold" />
                  <div className="socket col-xs-12 col-sm-12 col-md-3 col-lg-4 pl-0">
                      <a target="_blank" href="https://supplino.com/stage/privacy-policy/">Privacy</a> | <a target="_blank" href="https://supplino.com/stage/terms-and-conditions/">Terms</a>
                  </div>
                  <div className="socket col-xs-12 col-sm-12 col-md-4 col-lg-3 text-lg-center pl-0">
                      &copy; 2022 Supplino, Inc. - all rights reserved
                  </div>
                  {/* <div className="socket col-xs-12 col-sm-12 col-md-5 col-lg-5 text-lg-right pr-0 pl-0">
                      <strong>Supplino</strong> is a platform powered by Supplino, Inc.
                  </div> */}
              </div>
            </div>
          </footer>
      </CContainer>
    </div>

  )
}

export default React.memo(ExpertFooter)
