
console.log(process.env)

export let BASE_URL
export let PUSHER_APP_KEY
export let PUSHER_CLUSTER

if(process.env.REACT_APP_ENV == "development"){
    console.log("inside dev")
    //  BASE_URL = "https://ag63j1xhkf.execute-api.us-east-2.amazonaws.com/staging"
     BASE_URL = "http://127.0.0.1:8002"
     PUSHER_APP_KEY = "8a03b0eb300a1caba7f0"
     PUSHER_CLUSTER = "us2"

}else if (process.env.REACT_APP_ENV == "staging"){
    console.log("inside stage")
    // BASE_URL = "http://127.0.0.1:8002"
     BASE_URL = "https://staging.app.supplino.com/"
     PUSHER_APP_KEY = "d0a15139598ec43baea3"
     PUSHER_CLUSTER = "us2"

}
else if (process.env.REACT_APP_ENV == "production"){
    console.log("inside prod")
        BASE_URL = "https://app.supplino.com/"
        PUSHER_APP_KEY = "d0a15139598ec43baea3"
        PUSHER_CLUSTER = "us2"
}
