import React from 'react';

const Home = React.lazy(() => import('../pages/Main/Home'));
const Login = React.lazy(() => import('../pages/Auth/Login'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'));
const ExpertJoinIndex = React.lazy(() => import('../pages/Main/ExpertJoin/ExpertJoinIndex'));
const RegisterToggle = React.lazy(() => import('../pages/Auth/RegisterToggle'));
const ExpertSteps = React.lazy(() => import('../pages/Main/ExpertJoin/ExpertJoinSteps'));
const Dashboard = React.lazy(() => import('../pages/Main/Dashboard'));
const ClientHome = React.lazy(() => import('../pages/Main/ClientHome'));
const ClientRegister = React.lazy(() => import('../pages/Main/Client/ClientOnboard/ClientRegister'));
const ClientOnboard = React.lazy(() => import('../pages/Main/Client/ClientOnboard/ClientOnboard'));
const ClientJoinSteps = React.lazy(() => import('../pages/Main/Client/ClientOnboard/ClientJoinSteps'));
const FindExperts = React.lazy(() => import('../pages/Main/Client/ClientOnboard/FindExpert/FindExperts'));
const ExpertLogin = React.lazy(() => import('../pages/Auth/Experts/ExpertLogin'));

const routes = [
        { path: '/', name: 'Home', component: ClientHome, exact: true },
        { path: '/client/register', name: 'ClientRegister', component: ClientRegister, exact: true },
        { path: '/expert', name: 'Expert Home', component: Home, exact: true },
        { path: '/client/about', name: 'ClientJoinSteps', component: ClientJoinSteps, exact: true },
        { path: '/client/onboard', name: 'ClientOnboard', component: ClientOnboard, exact: true },
        { path: '/login', name: 'Login', component: Login, exact: true },
        { path: '/register', name: 'Register', component: RegisterToggle, exact: true },
        { path: '/expertJoin', name: 'ExpertJoinIndex', component: ExpertJoinIndex, exact: true },
        { path: '/passwordReset/:id', name: 'passwordReset', component: ResetPassword, exact: true },
        { path: '/expertSteps', name: 'ExpertSteps', component: ExpertSteps, exact: true },
        { path: '/dashboard', name: 'dashboard', component: Dashboard, exact: true },
        { path: '/client/findExperts', name: 'Find Expert', component: FindExperts, exact: true },
        { path: '/expert/login', name: 'Find Expert', component: ExpertLogin, exact: true },
];

export default routes;
