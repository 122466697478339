import React, {useState, useEffect} from "react"
import {
    CModal,
    CModalBody,
    CContainer,
    CForm,
    CAlert,
    CFormGroup,
    CLabel,
    CInput,
    CRow,
    CCol,
    CSelect,
    CTextarea,
    CButton,
    CInvalidFeedback,
    CInputFile
} from "@coreui/react";
import CIcon from '@coreui/icons-react'
import {resetPassword} from "../actions/UserActions"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import queryString from "query-string";

const ForgotPasswordModal = (props) => {

    let params = queryString.parse(props.location.search);

    const [ error, setError ] = useState(false)
    const [ showSuccess, setShowSuccess ] = useState(false)
    const [ projectItems, setProjectItems ] = useState([])
    const [ loader, setLoader ] = useState(false)
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {

    }, []);

    const onSubmit = (data) => {
        setLoader(true)
        props.resetPassword(data).then(() => {
            setShowSuccess(true)
            setLoader(false)
        }).catch((err) => {
            setError(err)
            setLoader(false)
        })
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <div className="d-flex align-items-center">
        <CContainer>
            <CModal
                show={props.modal}
                onClose={() => props.toggle(!props.modal)}
                // size={"lg"}
                style={{borderRadius: "10px"}}
                >
                <CModalBody className="p-4 pt-2" >
                    { props.modal && <CIcon name="cil-x-circle" size="xl" className="closeIcon pointer" onClick={() => props.toggle(!props.modal)} /> }
                    <p className="subHeading mb-1 bold" >{ showSuccess ? "Check your email" : "Forgotten your password?"} </p>
                    <p className="p mb-4" >

                        { !showSuccess ? "Don't worry, we'll send you a message to help you reset your password." : "We've sent you an email to help you reset your password. Remember to check your spam inbox if you can't find it."}</p>

                    { !showSuccess ? (<CForm onSubmit={handleSubmit(onSubmit)} >
                    { error && <CAlert color="danger">
                      {error}
                    </CAlert>}
                        <CRow>
                            <CCol xs="12" >
                                <CFormGroup className="pr-1">
                                    {/* <CLabel htmlFor="exampleInputName2" className="pr-1">Email Address</CLabel> */}
                                    <CInput className="customInput" placeholder="Email address" name="email" invalid={errors.email && true}
                                    innerRef={register({
                                        validate: {
                                            required: value => value != "" && value != null ? true : false,
                                            emails: value => {
                                                var isGood = true
                                                var res = value.split(",")
                                                res.map((email) => {
                                                    if(!validateEmail(email.trim())){
                                                        isGood = false
                                                    }
                                                })
                                                return isGood
                                            }
                                          }
                                    })} />
                                    {errors.email && <CInvalidFeedback>{errors.email.type == "required" ? "Can we get your email?" : "Invalid email"}</CInvalidFeedback>}
                                </CFormGroup>
                            </CCol>

                        </CRow>

                        <CRow className="mt-4" >
                            <CCol className="text-center">
                                <CButton color="primary" className="btn200 btn-pill" type="submit" disabled={loader} >{ loader ? "Sending..." :  "Continue" }</CButton>
                            </CCol>
                        </CRow>
                    </CForm>) : (
                        <CRow className="mt-4" >
                            <CCol className="text-center" >
                                <CButton color="primary" className="btn200 btn-pill" onClick={() => props.toggle(!props.modal)} >Done</CButton>
                            </CCol>
                        </CRow>
                    )}
                </CModalBody>
            </CModal>
            </CContainer>
        </div>
        )
}

const mapDispatchToProps = {
    resetPassword,
};
export default connect(null, mapDispatchToProps)(withRouter(ForgotPasswordModal));
