import React, {useState, useEffect, useRef} from "react";
import {
    CRow,
    CCol,
    CInvalidFeedback,
    CSelect,
    CInputGroupPrepend,
    CInputGroupText,
    CInputGroup,
    CForm,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import {useForm} from 'react-hook-form';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import StepComp from "./StepComp";

const RoleDetails = (props) => {
    const {reqData, data} = props
    const {register, handleSubmit, errors, setError, watch} = useForm()
    const [volume, setVolume] = useState(reqData.details.expert_max_budget)
    const [maxHours, setMaxHours] = useState(reqData.details.expert_hours_week)
    const [location, setLocation] = useState(reqData.details.expert_location)
    const watchAllFields = watch()

    const onSubmit = (data) => {
        data["expert_max_budget"] = volume
        data["expert_hours_week"] = maxHours
        props.saveStepData("details", data)
        props.incrementStep()
    };

    return (
        <div>
            <CForm>
            <CRow>
                <CCol md="6" >
                    <div>
                    <p className="heading"  >Role Details - Some final details about the role</p>
                    <div className="mb-4">
                        <p className="mb-2 bold" >Location - Where will this project be?</p>
                        <CInputGroup className="mb-4 text-left">
                            <CInputGroupPrepend>
                                <CInputGroupText className="customInputIcon">
                                    <CIcon name="cil-user"/>
                                </CInputGroupText>
                            </CInputGroupPrepend>
                            <CSelect custom size="md"
                                name="expert_location" style={{maxWidth: "100%"}}
                                className="cutomSelect d-block"
                                invalid={errors.expert_location && true}
                                innerRef={register({required: true})}
                                onSelect = {(e) => setLocation(e.target.value)}
                                defaultValue={location}
                                >
                                <option value="">Select Option</option>
                                {Object.keys(data.preferences.expertLocation).map((key) => (
                                    <option value={key}> {data.preferences.expertLocation[key]}</option>
                                ))}
                            </CSelect>
                            {errors.expert_location &&
                            <CInvalidFeedback>Field is required</CInvalidFeedback>}
                        </CInputGroup>
                    </div>
                    <div className="mb-4">
                        <p className="mb-2 bold" >Role - What's the max budget for this engagement?</p>
                        <p className="" >${volume}/hour</p>
                        <div className="slider-vertical" >
                        <Slider
                            value={volume}
                            max={200}
                            onChange={(value) => {
                                setVolume(value)
                            }}
                        />
                        <p>Max hourly rate*</p>
                        </div>
                    </div>

                        <div className="mb-4">
                            <p className="mb-2 bold" >Hours - Approximate hours per week?</p>
                            <p className="" >{maxHours} hrs/ week</p>
                            <div className="slider-vertical" >
                                <Slider
                                    value={maxHours}
                                    max={100}
                                    onChange={(value) => {
                                        setMaxHours(value)
                                    }}
                                />
                                <p>Max hours per week*</p>
                            </div>
                        </div>
                </div>
                </CCol>
            </CRow>
            </CForm>
            <StepComp onSubmit={handleSubmit(onSubmit)} decrementStep={() => props.decrementStep("details", {
                ...watchAllFields,
                ...{expert_max_budget: volume, expert_hours_week: maxHours}
            })}  />
        </div>

    )
}

export default RoleDetails