import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CCard,
    CCardBody,
    CTabs,
    CJumbotron,
    CContainer,
    CModal,
    CModalBody,
    CButton
} from "@coreui/react";
import SetupProfile from "../../../components/ExpertJoin/SetupProfile";
import ExpertiseFirst from "../../../components/ExpertJoin/ExpertiseFirst";
import ExpertiseSecond from "../../../components/ExpertJoin/ExpertiseSecond";
import Preferences from "../../../components/ExpertJoin/Preferences";
import { STORE } from "../../../helpers/CommonHelper";
import { getExpertMeta, updateExpertCat, updateSelectedSkill } from "../../../actions/ExpertActions";
import Loader from "../../../components/Loader";
import ExpertJoinIndex from "./ExpertJoinIndex";
import ExpertCall from "../../../components/ExpertJoin/ExpertCall";
import CallConfirmed from "../../../components/ExpertJoin/CallConfirmed";
import ExpertSkills from "../../../components/ExpertJoin/ExpertSkills";

const ExpertJoinSteps = props => {

    const [currentStep, setCurrentstep] = useState(0)
    const [expertMeta, setExpertMeta] = useState([])
    const [skills, setSkills] = useState([])
    const [preferencesMeta, setPreferencesMeta] = useState([])
    const [loader, setLoader] = useState(true)
    const [calendlyEvent, setCalendlyEvent] = useState()
    const [reSched, setResched] = useState(false)
    const [selectedCats, setSelectedCats] = useState({
        ids: []
    })
    const [selectedSkills, setSelectedSkills] = useState({
        ids: []
    })
    const [selectedPref, setSelectedPref] = useState({
        availability: "",
        comments: "",
        currency: "",
        interest: "",
        know_us: "",
        max_work_day: "",
        rate: "",
        willing_travel: ""
    })
    const [profile, setProfile] = useState(
        {
            "username": "",
            "first_name": "",
            "last_name": "",
            "email": "",
            "image": null,
            "mobile": null,
            "address1": "",
            "address2": "",
            "country_code": null,
            "postal_code": "",
            "city": "",
            "state": ""
        }
    )

    const updateStep = (step) => {
        setCurrentstep(step)
    }

    const arrayChunks = (resp) => {
        var chunks = []
        chunks.push(resp.slice(0,Math.round(resp.length/2)))
        chunks.push(resp.slice(Math.round(resp.length/2),resp.length))
        return chunks
    }

    useEffect(() => {
        STORE.dispatch(getExpertMeta("type=expert")).then((resp) => {
            if(resp?.redirect){
                if(resp.redirect == "CALL_SCHEDULE"){
                    updateStep(5)
                }else if(resp.redirect == "CALL_CONFIRMED"){
                    setCalendlyEvent(resp.calendly_event)
                    updateStep(6)
                }else{
                     props.history.push("/dashboard")
                }
            }else{
                // setExpertMeta(arrayChunks(resp.categories))
                setExpertMeta(resp.categories)
                setSkills(resp.skills)
                setSelectedSkills(resp.selectedSkills)
                setPreferencesMeta(resp.preferences)
                setProfile(resp.profile)
                setSelectedCats(resp.selectedCats)
                setSelectedPref(resp.selectedPref)
            }
            setLoader(false)
        })
    }, []);

    const persistSelectecPref = (resp) => {
        var copy = JSON.parse(JSON.stringify(selectedPref))
        setSelectedPref({...copy, ...resp})
    }

    const handleCatClick = (checked, catId, title) => {
        var reqData = {}
        if(checked){
            reqData = {
                add: true,
                catId: catId,
                title: title
            }
        }else{
            reqData = {
                del: true,
                catId: catId
            }
        }
        STORE.dispatch(updateExpertCat(reqData))
    }

    const handleSkillClick = (checked, catId, title) => {
        var reqData = {}
        if(checked){
            reqData = {
                add: true,
                catId: catId,
                title: title
            }
        }else{
            reqData = {
                del: true,
                catId: catId
            }
        }
        STORE.dispatch(updateSelectedSkill(reqData))
    }

    const handleResched = () => {
        setResched(!reSched)
        updateStep(5)
    }

    const renderStep = () => {
        if(currentStep == 0){
            return <ExpertJoinIndex updateStep={updateStep} />
        }
        else if(currentStep == 1){
            return <SetupProfile updateStep={updateStep} step={currentStep} data={profile} setProfile={setProfile} />
        }else if (currentStep == 2){
            return <ExpertiseFirst data={expertMeta} setData={setExpertMeta} total={expertMeta.length} updateStep={updateStep} step={currentStep} handleCatClick={handleCatClick} selectedCats={selectedCats} setSelectedCats={setSelectedCats}  />
        }else if (currentStep == 3){
            return <ExpertSkills data={skills} updateStep={updateStep} step={currentStep} handleCatClick={handleSkillClick} selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills}  />
        }
        else if (currentStep == 4){
            return <ExpertiseSecond data={expertMeta} total={expertMeta.length} updateStep={updateStep} step={currentStep} handleCatClick={handleCatClick} selectedCats={selectedCats}  setSelectedCats={setSelectedCats} />
        }
        else if (currentStep == 5){
            return <Preferences persistSelectecPref={persistSelectecPref} preferencesMeta={preferencesMeta} updateStep={updateStep} step={currentStep} selectedPref={selectedPref}  />
        }else if (currentStep == 6){
            return <ExpertCall data={profile} setCalendlyEvent={setCalendlyEvent} reSched={reSched} calendlyEvent={calendlyEvent} updateStep={updateStep} step={currentStep} />
        }else if (currentStep == 7){
            return <CallConfirmed expert={true} handleResched={handleResched} updateStep={updateStep} step={currentStep} />
        }
    }

    return (
        <>
            <div className="mt-5" >
            {loader ? <Loader/> : renderStep()}
            </div>
                
        </>
    );
};

export default (withRouter(ExpertJoinSteps));
