import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CRow,
    CButton
} from "@coreui/react";
import store from "../../store";
import CIcon from '@coreui/icons-react';

const ClientHome = props => {

    const {
        auth: {authenticated}
    } = store.getState();


    useEffect(() => {
    }, []);

    return (
        <>
            <CRow style={{height: "100%", marginTop: "5%", marginBottom: "5%"}} >
                <CCol xs="12" md="7" >
                    <div>
                        <p  className="mb-1" style={{fontSize: "2.75rem"}} >Manufacturing & supply chain success</p>
                        <p  className="bold"  style={{fontSize: "2.75rem"}}  >Find the Experts you need, fast</p>
                        <p className="subHeading mt-4">
                            Whether you are just starting with your product idea, or are well into your product lifecycle, our experts are here to help. Tap into our flexible workforce in manufacturing and supply chain to supercharge your company’s scaling and growth.
                        </p>
                    </div>
                    <div className="d-flex flex-row justify-content-between" style={{marginTop: "4rem"}} >
                        <CButton
                            size="lg"
                            onClick={() => props.history.push("/client/register")}
                            className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center" >
                            <p className="mb-0 lead" >Get Started</p>
                            <CIcon size="xl"name="cil-arrow-right"/>
                        </CButton>
                        <CButton onClick={() => window.open("https://supplino.com/contact-us-supplino/", '_blank').focus()} size="lg" className="btn200 btn-pill d-flex justify-content-around align-items-center" >
                            <p className="mb-0 bold lead" >Contact Sales</p>
                            <CIcon
                                size="xl"
                                name="cil-arrow-right"
                            />
                        </CButton>
                    </div>
                </CCol>
                <CCol>
                </CCol>
            </CRow>
        </>
    );
};

export default (withRouter(ClientHome));
