import React from 'react';
import store from '../../store';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../../helpers/auth';
import PropTypes from 'prop-types';
import ExpertLayout from '../../containers/Experts/ExpertLayout';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const ExpertAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = store.getState();
      if (!authenticated) {
        setIntendedUrl(props.location.pathname);
      }

      return authenticated ? (
        <ExpertLayout {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

ExpertAuth.propTypes = propTypes;
ExpertAuth.displayName = 'Auth Route';

export default ExpertAuth;
