import React from 'react';
import store from '../store';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TheLayout } from '../containers';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = store.getState();
      return !authenticated ? (
        <TheLayout {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/client/onboard',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

GuestRoute.propTypes = propTypes;
GuestRoute.displayName = 'Guest Route';

export default GuestRoute;
