import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'
import {
    cilUserFollow,
    cilUserUnfollow,
    cilChartPie,
    cilBasket,
    cilSpeedometer,
    cilXCircle,
    cilArrowLeft,
    cilUser,
    cilLockLocked,
    cilWarning,
    cilBell,
    cilArrowRight,
    cilChevronTop,
    cilChevronBottom,
    cilCaretBottom,
    cilCaretRight,
    cibLinkedinIn,
    cibTwitter,
    cibYoutube,
    cibInstagram,
    cibMediumM,
    cilHome,
    cilBook,
    cilBriefcase,
    cilChatBubble,
    cilPeople,
    cilSettings,
    cilPlus,
    cilMinus,
    cilPencil
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
    cilUserFollow,
    cilUserUnfollow,
    cilChartPie,
    cilBasket,
    cilSpeedometer,
    cilXCircle,
    cilArrowLeft,
    cilChevronTop,
    cilChevronBottom,
    cilPlus,
    cilMinus,
    cilBell,
    cilUser,
    cilLockLocked,
    cilWarning,
    cilArrowRight,
    cilCaretBottom,
    cilCaretRight,
    cibLinkedinIn,
    cibTwitter,
    cibYoutube,
    cibInstagram,
    cibMediumM,
    cilHome,
    cilBook,
    cilBriefcase,
    cilChatBubble,
    cilPeople,
    cilSettings,
    cilPencil
})
