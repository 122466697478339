import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CRow,
    CCard,
    CCardBody,
    CButton
} from "@coreui/react";
import CIcon from '@coreui/icons-react';

const ClientOnboard = props => {

    useEffect(() => {
    }, []);

    return (
        <>
            <CCard className="mt-5 pl-4 pr-4" >
                <CCardBody >
                    <p className="heading bold mb-3" >Structure your proposal with three easy steps</p>
                    <div style={{display: "flex", flexDirection: "column"}}  >
                        <p className="lead" >You can apply to our network os three simple steps by filling out the application. Please be thorough as we will use it to evaluate the best project fit and propose you interesting projects. Note: You control your information, and your profile will  be shared only once you explicitly agree through our pitching process.</p>
                    </div>
                    <CRow className="mt-5"  >
                        <CCol>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "80px", height: "80px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                <p className="mb-0 heading bold" style={{color: "#fff"}} >1</p>
                                </div>
                                <div className="heading" >Your Product</div>
                            </div>
                        </CCol>
                        <CCol>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "80px", height: "80px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                <p className="mb-0 heading bold" style={{color: "#fff"}} >2</p>
                                </div>
                                <div className="heading" >Your Needs</div>
                            </div>
                        </CCol>
                        <CCol>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                                <div className="mr-3 yellowBg" style={{width: "80px", height: "80px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                    <p className="mb-0 heading bold" style={{color: "#fff"}} >3</p>
                                </div>
                                <div className="heading" >Find an Expert</div>
                            </div>
                        </CCol>
                    </CRow>
                    
                    <div className="d-flex flex-row justify-content-around pt-5 pb-5" style={{marginTop: "3rem"}} >
                        <CButton
                            size="lg"
                            onClick={() => props.history.push("/client/about")}
                            className="btn-pill btn-primary d-flex justify-content-around align-items-center btn200" >
                            <p className="mb-0 lead bold mr-3" >Get Started</p>
                            <CIcon size="xl"name="cil-arrow-right"/>
                        </CButton>

                        <CButton
                            size="lg"
                            onClick={() => props.history.push("/client/findExperts")}
                            className="btn-pill btn200 btn-outline-primary d-flex justify-content-around align-items-center" >
                            <p className="mb-0 lead bold mr-3" >Skip, I know what I need</p>
                            <CIcon size="xl"name="cil-arrow-right"/>
                        </CButton>

                    </div>
                </CCardBody>
            </CCard>
        </>
    );
};

export default (withRouter(ClientOnboard));