import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { openRequests } from '../../actions/ExpertActions';
import Loader from '../../components/Loader';
import { STORE } from '../../helpers/CommonHelper';

function Dashboard() {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const history = useHistory()

    useEffect(() => {
        STORE.dispatch(openRequests()).then((resp) => {
            setData(resp)
            setLoading(false)
        })
    }, [])

    const handleNewRequest = () => {
        // STORE.dispatch({
        //     type: "SET_SHOW_COMPANY",
        //     payload: false
        // })
        history.push("/client/onboard")
    }

    return (
        loading ? <Loader /> : (
            <div>
                <h1 className="mt-4 mb-3" >Welcome, {data.username}</h1>
                <CCard>
                    {data.openRequests.length == 0 ? (
                        <CCardBody className="p-5" >
                            <p className='subHeading text-center' >You do not have any open request, start creating one by clicking the button below</p>
                            <CRow className="mt-4">
                                <CCol className="d-flex align-items-center justify-content-center">
                                    <CButton
                                        size="lg"
                                        onClick={() => history.push("/client/onboard")()}
                                        className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center " >
                                        <p className="mb-0 lead" >Start A New Request</p>
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    ) : (
                        <CCardBody>
                        <p className='heading' >Open Requests</p>
                        <p className='subHeading' >You currently have the following open requests</p>

                        {data.openRequests.map((request) => {
                            return <CRow className="mb-4" >
                                <CCol md="6" className="p-3 ml-4" style={{border: "1px solid lightgrey", borderRadius: "5px", background: "rgb(248, 248, 248)"}} >
                                    <p className="lead bold " >Summary</p>
                                    <div className="d-flex align-items-center"  >
                                    <div style={{flex: 1}} > 
                                        <CRow className="mb-2" >
                                            <CCol className="lead"  xs="3" >Talent Type</CCol>
                                            <CCol className="lead"  >
                                                {(request.selectedCats.combined.map((v) => {
                                                return <span>{v.childTitle} {" "}</span>
                                            }))}</CCol>
                                        </CRow>
                                        <CRow className="mb-2" >
                                            <CCol className="lead" xs="3">Skills</CCol>
                                            {request.selectedSkills.length > 0 ? (
                                                <CCol  className="lead" >{(request.selectedSkills.map((v) => {
                                                    return <span>{v.childTitle} {" "}</span>
                                                }))}</CCol>
                                            ) : (
                                                <CCol  className='lead'  >N/A</CCol>
                                            )}
                                            
                                        </CRow>
                                        <CRow className="mb-2" >
                                            <CCol className="lead" xs="3" >Location</CCol>
                                            {request.expert_location ? (
                                                <CCol  className="lead ">{request.expert_location}</CCol>
                                            ) : (
                                                <CCol  className='lead' >N/A</CCol>
                                            )}
                                        </CRow>
                                        <CRow className="mb-2" >
                                            <CCol className="lead " xs="3">Rate</CCol>
                                            {request.expert_max_budget ? (<CCol xs="3" className="lead ">${request.expert_max_budget} / Hour</CCol>) : (
                                                <CCol className='lead' >N/A</CCol>
                                            )}
                                        </CRow>
                                        <CRow className="mb-2" >
                                            <CCol className="lead " xs="3" >Hours</CCol>
                                            {request.expert_hours_week ? <CCol  className="lead ">{request.expert_hours_week} hours per week</CCol> : (
                                                <CCol  className='lead' >N/A</CCol>
                                            )}
                                        </CRow>
                                    </div>
                                    </div>
                                </CCol>
                            </CRow>
                        })}
                        <CRow className="mt-5">
                            <CCol className="d-flex align-items-center justify-content-end">
                                <CButton
                                    size="lg"
                                    onClick={() => handleNewRequest()}
                                    className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center " >
                                    <p className="mb-0 lead" >Start A New Request</p>
                                </CButton>
                            </CCol>
                        </CRow>
                        </CCardBody>
                    )}
                    
                </CCard>
            </div>
        )
    );
}

export default Dashboard;
