import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CRow,
    CCol,
    CButton,
    CCard,
    CCardBody,
    CInputCheckbox,
    CInput
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { useSelector } from "react-redux";

const ClientProduct = props => {
    const {data} = props
    const [others, setOthers] = useState({})
    const showCompanyStep = useSelector(state => state.client.showCompany)

    const arrayChunks = (parentName, resp) => {
        var sorted = []
        // SORT CHILDS
        if(parentName == "Product Stages"){
            // DO CUSTOM SORTING
            var order = [
                "Idea stage",
                "Product design / prototyping",
                "Early production manufacturing",
                "Scaling and growth",
                "Maturity",
                "Other"
            ]
            order.map((child) => {
                resp.map((cat) => {
                    if(cat.title == child){
                        sorted.push(cat)
                    }
                })
            })
        }else{
            sorted = resp.sort(function(a, b){return a.title-b.title});
        }

        var other = null
        var exludedData = []
        sorted.map((cat) => {
            if(cat.title != "Other"){
                exludedData.push(cat)
            }else{
                other = cat
            }
        })
        var chunks = [] 
        var chunkSize = 8;
        if(parentName == "Product Stages"){
            chunkSize = 6;
        }
        var i,j, temporary, chunk = chunkSize;
        for (i = 0,j = exludedData.length; i < j; i += chunk) {
            temporary = exludedData.slice(i, i + chunk)
            chunks.push(temporary)
        }
        if(other){
            chunks[chunks.length-1].push(other)
        }
        return chunks
    }

    const handleCheckbox = (checked, childId, title) => {
        var catsCopy = {...props.selectedCats}
        if(checked){
            catsCopy.ids.push(childId)
        }else{
            catsCopy.ids.splice(catsCopy.ids.indexOf(childId),1)
        }
        props.setSelectedCats(catsCopy)
        if(title == "Other"){
            title = others[childId]
        }
        props.handleCatClick(checked, childId, title)
    }

    const handleOther = (e, childId) => {
        var othersCopy = {...others}
        othersCopy[childId] = e.target.value
        setOthers(othersCopy)
        props.handleCatClick(true, childId, e.target.value)
    }

        return <>
        <div>
            <div className="d-flex align-items-center mb-3" >
                <div className="mr-3 yellowBg" style={{width: "70px", height: "70px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                    <p className="mb-0 heading bold" style={{color: "#fff"}} >1</p>
                </div>
                <p className="heading">Your Product & Company - 1 of 2</p>
            </div>
        <CCard className="boxShadow">
            <CCardBody>
            {data && data.map((parent) => (
                        <div>
                            <div className="d-flex align-items-center" >
                                <p className="heading mr-4" >{parent.title}</p>
                                <p className="lead bold" >{parent?.description}</p>
                            </div>
                            <CRow>
                                {arrayChunks(parent.title, parent.childs).map((childChunk) => (
                                    <CCol className="mb-4" >
                                        {childChunk.map((child, index) => (
                                            <div>
                                                <div className="d-flex align-items-center ml-4 mb-2" >
                                                    <CInputCheckbox defaultChecked={props.selectedCats.ids.includes(child.uuid) ? true : false} onClick={(e) => handleCheckbox(e.target.checked, child.uuid, child.title)} className="mr-2"  />
                                                    <p className="mb-0" >{child.title}</p>
                                                </div>
                                                {(child.title == "Other" && props.selectedCats.ids.includes(child.uuid)) && <CInput className="mr-4 customInput bold" defaultValue={props.selectedCats.titles[props.selectedCats.ids.indexOf(child.uuid)]} name="other" onChange={(e) => handleOther(e, child.uuid)} ></CInput>}
                                            </div>
                                        ))}
                                    </CCol>
                                ))}
                            </CRow>
                        </div>
                    ))}

                <CRow className="mt-5">
                    <CCol className="d-flex align-items-center justify-content-end">
                        <CButton
                            size="lg"
                            onClick={() => props.updateStep(props.step-1)}
                            className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                            <CIcon size="xl"name="cil-arrow-left"/>
                            <p className="mb-0 lead" >Back</p>
                        </CButton>
                        <CButton
                            size="lg"
                            onClick={() => !showCompanyStep ? props.updateStep(props.step+2) : props.updateStep(props.step+1)}
                            className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                            <p className="mb-0 lead" >Next Step</p>
                            <CIcon size="xl"name="cil-arrow-right"/>
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        </div>
    </>
}

export default (withRouter(ClientProduct));
