import { combineReducers } from 'redux';
import auth from './auth';
import loading from './loading';
import core from './core';
import client from './client';

export default combineReducers({
    auth,
    loading,
    core,
    client
});
