import React, { useState } from "react";
import {
    CRow,
    CCol,
} from "@coreui/react";
import Multiselect from 'multiselect-react-dropdown';
import StepComp from "./StepComp";

const SearchSkills = (props) => {

    const [data, setData] = useState(props.reqData.selectedSkills)

    const handleSelect = (d) => {
       var objs = []
        d.map((expertise) => {
            objs.push(expertise)
       })
       setData(objs)
    }

    const onSubmit = () => {
        props.saveStepData("selectedSkills", data)
        props.incrementStep()
    }

    const addSkill = (d) => {
        var found = false
        data.map((skill) => {
            if(skill.childId == d.childId){
                found = true
            }
       })
       if(!found){
           setData([...data, d])
       }
    }

    const prepareChips = () => {
        return props.data.skills.map((skill, index) => {
            if(skill.childTitle == "Industrial design" ||
            skill.childTitle == "Daily purchasing operations" ||
            skill.childTitle == "Supply chain risk management" ||
            skill.childTitle == "SAP ERP"){
                return <p className="pointer mr-3 popularTag" onClick={() => addSkill(skill)} >{skill.childTitle}</p>
            }
        })
    }

    return (
        <div>
        <div>
        <p className="heading" >Select needed skills</p>
        <CRow>
         <CCol md="8" >
             <div className="mb-4">
                 {/* <CInput className="customInput" invalid={errors.email && true} name="email" type="text"
                             placeholder="Select or type the functional expertise you need" />
                 {errors.username &&
                 <CInvalidFeedback>{errors.username.message}</CInvalidFeedback>} */}
             <Multiselect
                 displayValue="childTitle"
                 groupBy="parentTitle"
                 selectedValues={data}
                 onRemove={handleSelect}
                 onSelect={handleSelect}
                 options={props.data.skills}
                 showCheckbox
                 showArrow={true}
                 placeholder="Select or type the needed additional skills"
                 style={
                     {multiselectContainer: {
                         borderRadius: "10px",
                     },
                     searchBox: {
                     },
                     inputField: { // To change input field position or margin
                         height: "30px",
                         width: "100%"
                     },
                     chips: { // To change css chips(Selected options)
                     },
                     optionContainer: { // To change css for option container
                         height: "900px"
                     },
                     option: { // To change css for dropdown options
                         
                     },
                     groupHeading: { // To chanage group heading style
                         fontWeight: "bold",
                         color: "dimgrey"
                     }
                 }}
             />
             
             </div>
         </CCol>
         <CCol xs="12"  >
             <div className="d-flex align-items-center mt-2" >
                 <p className="p bold mr-3" >Most Popular: </p>
                 {prepareChips()}
             </div>
         </CCol>
     </CRow>
     <StepComp decrementStep={() => {props.decrementStep("selectedSkills", data)}} onSubmit={onSubmit}  />
    </div>
    </div>
    )
}

export default SearchSkills