
import React from "react";
import {
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButton,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { useHistory } from "react-router-dom";

const StepComp = (props) => {
    const history = useHistory()
    return (
        <CRow className="mt-5">
            <CCol className="d-flex align-items-center justify-content-end">
                {props.hideBack != undefined && <CButton
                    size="lg"
                    onClick={() => history.push("/client/onboard")()}
                    className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                    <CIcon size="xl"name="cil-arrow-left"/>
                    <p className="mb-0 lead" >Back</p>
                </CButton>}
                {props.hideBack == undefined && <CButton
                    size="lg"
                    onClick={() => props.key ? props.decrementStep(props.key, props.obj) : props.decrementStep()}
                    className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                    <CIcon size="xl"name="cil-arrow-left"/>
                    <p className="mb-0 lead" >Back</p>
                </CButton>}
                <CButton
                    size="lg"
                    onClick={() => props.onSubmit()}
                    className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                    <p className="mb-0 lead" >Next</p>
                    <CIcon size="xl"name="cil-arrow-right"/>
                </CButton>
            </CCol>
        </CRow>
    )
}

export default StepComp
