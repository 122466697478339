import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CRow,
    CButton
} from "@coreui/react";
import store from "../../store";
import landingBg from "../../assets/images/landingBg.png"

const Home = props => {
    
    const {
        auth: {authenticated}
    } = store.getState();


    useEffect(() => {
    }, []);

    return (
        <>
            <CRow className={"whiteText"} style={{height: "100%", marginTop: "5%", marginBottom: "5%"}} >
                <CCol xs="12" md="5" >
                    <div  >
                        <p  style={{fontSize: "3rem", fontWeight: "bold"}} className="mb-5" >Let’s build great product businesses.</p>
                        <p className="subHeading" >Power the supply chains of thoughtful entrepreneurs and small businesses, </p>
                        <p className="subHeading" >Thousands of projects - great possibilites to design, manufacture and deliver. </p>
                        <div className="d-flex flex-row justify-content-center" style={{marginTop: "4rem"}} >
                            <CButton
                                size="lg"
                                onClick={() => { props.history.push( authenticated ? "/expertSteps" : "/register") }}
                                className="btn200 btn-pill yellowBtn" >
                                <p className="mb-0 lead bold" style={{color: "#000F5F"}} >Create a Profile</p>
                            </CButton>
                            {/* <CButton
                                onClick={() => { props.history.push( authenticated ? "/client/onboard" : "/client") }}
                                size="lg" className="btn200 btn-pill btn-outline-primary" >
                                <p className="mb-0 lead" >Find Talent</p>
                            </CButton> */}
                    </div>
                    </div>
                </CCol>
                <CCol>
                    <div className="text-center" >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}} className="mb-5" >
                            <div className="bold lead whiteText pointer"> Find Projects</div>
                            <div className="bold lead whiteText pointer"> Get Paid</div>
                            <div className="bold lead whiteText pointer"> What we do</div>
                        </div>
                        <div>
                            <img className="img-fluid" src={landingBg} />
                        </div>
                    </div>
                    
                </CCol>
            </CRow>
        </>
    );
};

export default (withRouter(Home));
