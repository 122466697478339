import React, {useState} from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CInvalidFeedback,
  CAlert,
  CFormGroup,
  CInputCheckbox,
  CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useForm } from 'react-hook-form';
import { getIntendedUrl, setIntendedUrl } from '../../helpers/auth';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {registerBeta} from "../../actions/auth"
import authImage from "../../assets/images/auth.png"
import {Divider} from '../../components/Divider';
import queryString from "query-string";

const RegisterBeta = (props) => {

  let params = queryString.parse(props.location.search);

  const { register, handleSubmit, errors, setError } = useForm();
  const [ loader, setLoader ] = useState(false)
  const [ mainError, setMainError ] = useState(false)
  const [ success, setSuccess ] = useState("")

  const onSubmit = (data) => {
    if(params.ref_id != undefined){
      data["ref_id"] = params.ref_id
    }
    setLoader(true)
      props.registerBeta(data).then(async (resp) => {
      setLoader(false)
      setSuccess("Successfully added into our mailing list!")
   }).catch((e) => {
      setLoader(false)
      setMainError(e)
   })
  };

  const handleLoginLink = () => {
    if(params.pid){
      props.history.push("/login?pid="+params.pid)
    }else{
      props.history.push("/login")
    }
  }

  return (
    <div className={props.modal != undefined ? "flex-row align-items-center" : "flex-row align-items-center mb-5 mt-5"}>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="6" className="leftLoginBox d-lg-flex align-items-center flex-column d-none" style={{padding: "50px"}} >
            <div>
              <p className="mb-3 heading" >Welcome to Buildit.studio</p>
              <p className="lead mb-3 subHeading text-left" >
              Sorry! We are in a closed beta period and are accepting a limited number of users.
              </p>
              <p className="lead mb-5 subHeading text-left" >
              Please sign up, and we will keep you informed of our next release.
              </p>
            </div>
            <div>
              <img className="img-fluid" src={authImage} />
            </div>
          </CCol>
          <CCol md="12" lg="6" className="rightLoginBox" >
          {/* <div className="text-center" style={{marginBottom: 30}} >
              <img src={Logo} width="300" />
              </div> */}
            { props.modal &&  <CIcon name="cil-x-circle" size="xl" className="closeIcon pointer" onClick={() => props.setModal()} />}
            <CCardGroup>
              <CCard className="p-4 loginCard">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)} > 
                    { mainError && <CAlert color="danger">
                      {mainError}
                    </CAlert>}
                    { success && <CAlert color="success">
                      {success}
                    </CAlert>}
                    <p className="heading  mb-3">Join our mailing list.</p>
                    <p className="lead subHeading mb-5 text-left">
                    Supplino unifies all the steps of product design, prototyping, supplier management, and delivery processes to make getting an idea into your customers’ hands faster and with less risk.
                    </p>
                    <p className="lead subHeading text-left mb-4" >Want to know more? Join our mailing list.</p>
                   
                    <CRow>
                        <CCol md="8" xs="12" className="p-md-0" >
                            <CFormGroup className="pr-1">
                            <CInput className="customInput" invalid={errors.email && true} name="email" type="text" placeholder="Email Address" innerRef={register({ required: { value: true, message: "Please fill out this field" }, pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address"
                                } })} />                                    
                                {errors.email && <CInvalidFeedback className="text-left" >{errors.email.message}</CInvalidFeedback>}
                            </CFormGroup>
                        </CCol>
                        <CCol className="p-md-0" >
                        <CFormGroup className="pr-1">
                            <CButton className="btn-pill btn200 bold" disabled={loader} color="primary" type="submit" >{loader ? "Processing...": "SIGN UP"}</CButton>
                        </CFormGroup>
                         
                        </CCol>
                    </CRow>
                    
                    <div className="mt-2 text-left" >
                        <CInputCheckbox name="terms" invalid={errors.terms && true} innerRef={register({required: {value: true, message: "Please tick this box if you wish to proceed"}})}   />
                        <p className="lead mb-0" >I accept BuildIt.studio’s <CLink target="_blank" download to={{pathname: "https://buildit.studio/launch/terms-and-conditions/"}} ><u className="yellowText bold" >terms and conditions</u></CLink > and <CLink target="_blank" download to={{pathname: "https://buildit.studio/launch/privacy-policy/"}}><u className="yellowText bold" >privacy policy</u></CLink>.</p>
                        {errors.terms && <CInvalidFeedback className="text-left" >{errors.terms.message}</CInvalidFeedback>}
                    </div>
                  
                    <CRow className="mt-5" >
                      <CCol xs="12" className="mt-2" >
                        <Divider className="mb-5" />
                        <div>
                            <p className="heading lead bold mb-2" >Already part of the Beta?</p>
                            <div>
                                <CButton className="mb-3 lead" color="link" style={{color: "inherit"}} onClick={() => handleLoginLink() } >Have an account? <b className="yellowText bold" >Log-in</b></CButton>
                            </div>
                        </div>

                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>

          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapDispatchToProps = {
  registerBeta
};

export default connect(null, mapDispatchToProps)(withRouter(RegisterBeta));