import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExpertLayout from '../../containers/Experts/ExpertLayout';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const ExpertNonLogin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (<ExpertLayout {...props} />)
    }
    }
  />
);

ExpertNonLogin.propTypes = propTypes;
ExpertNonLogin.displayName = 'Non Login Route';

export default ExpertNonLogin;
