import React from "react"
import dummy from "../assets/dummy.jpg"
import store from "../store"
import { toast } from 'react-toastify';
import moment from "moment-timezone";

export let FB_ID
export const STORE = store
export const TOAST = toast

export const moduleTypes = [
    "normal",
    "registered",
    "subscribed"
]

if(process.env.REACT_APP_ENV == "production"){
    FB_ID = "322684238911004"
}else{
    FB_ID = "322684238911004"
}

export const GOOGLE_ID = "469705255994-s5oodbtpr83mh560dhjuk0k69lrb9ips.apps.googleusercontent.com"

//export const FB_ID = "322684238911004"
//export const GOOGLE_ID = "693530639699-s22pqhepbrond0qmahb85ovkiu01cfhi.apps.googleusercontent.com"

export const getMedia = (url) => {
    if(url){
        return url;
    }
    return dummy;
}

export const parseProgress = (progress) => {
    if(progress){
        return progress.toFixed(2);
    }
    return 0;
}

export const centsToDollars = (cents) => {
    var num = cents;
    var dollars = num / 100;
    dollars = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
    return dollars
}

export const mapping =  {
    one_to_one_channel: "presence-bis-user-",
    personal_presence: "presence-bis-channel-",
    user_chat_event: "bis-user-event-",
    group_channel : "presence-bis-group-",
    group_event : "bis-group-event-",

    online : "presence-bis-online",
    online_event : "bis-online-event"
}

export const friendlyTime = (time) => {
    return moment.utc(time).local().format("hh:mm A")
}

export var UID = function () {
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const getCountryNameName = (name) => {
    if(name == "Bahamas The" || name == "Gambia The"){
        return name.replace("The", "")
    }
    return name
}