import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    CCol,
    CRow,
    CCreateElement,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavTitle,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem, CNavbarBrand, CToggler, CContainer, CBadge,
} from '@coreui/react'
import {withRouter, Link} from 'react-router-dom';
import Logo from "../assets/brand/logo.svg"
import CIcon from '@coreui/icons-react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import { mapping, STORE } from '../helpers/CommonHelper';
import { Alert } from 'bootstrap';
// sidebar nav config
// import navigation from './_nav'

const TheSidebar = (props) => {
    const dispatch = useDispatch()
    const show = useSelector(state => state.core.sidebarShow)
    const [loading, setLoading] = useState(true)
    // const [navData, setNavData] = useState()

    useEffect(() => {
        
    }, []);

    return (
        <CSidebar
            style={{background: "#f0f4f7", fontSize: ".9rem"}}
            show={show}
            hideOnMobileClick={true}
            onShowChange={(val) => dispatch({type: 'set', sidebarShow: val})}>
            {/*{<CSidebarBrand style={{background: "#fff"}} className="d-md-down-none" to="/studio">*/}
            {/*    <img src={Logo} width="200"/>*/}
            {/*</CSidebarBrand>}*/}
            <CRow className="d-lg-none d-sm-flex pl-3 pt-2 pb-2 border-bottom mr-0">
                <CCol xs="9" sm="9" md="9">
                    <CNavbarBrand href="/">
                        <img src={Logo} width={"120"}/>
                    </CNavbarBrand>
                </CCol>
                <CCol xs="3 mt-1" sm="3" md="3">
                    <div className="custom-head-toggle">
                        <CToggler
                            inHeader
                            className="d-lg-none"
                            onClick={toggleSidebarMobile}
                        />
                        <CToggler
                            inHeader
                            className="d-md-down-none"
                            onClick={toggleSidebar}
                        />
                    </div>
                </CCol>
            </CRow>
            <CSidebarBrand className="d-md-down-none" style={{background: "#fff"}} to="/">
                <CIcon
                    className="c-sidebar-brand-full"
                    name="logo-negative"
                    height={35}
                />
                <CIcon
                    className="c-sidebar-brand-minimized"
                    name="sygnet"
                    height={35}
                />
            </CSidebarBrand>
            
            <CSidebarMinimizer className="c-d-md-down-none"/>
        </CSidebar>
    )
}

export default (withRouter(TheSidebar));
