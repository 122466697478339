import React from 'react'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'
import { ToastContainer } from 'react-toastify';

const TheLayout = () => {

  return (
    <div className="c-app c-default-layout" style={{background: "#f9f9f9"}} >
      {/* <TheSidebar className="sidebar-hidden" /> */}
      <div className="c-wrapper"  >
        <TheHeader/>
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter/>
        <ToastContainer position="bottom-center" autoClose={3000} />
      </div>
    </div>
  )
}

export default TheLayout
