import React, { useEffect, useState } from "react";
import {
    CRow,
    CCol,
    CInvalidFeedback,
    CInput,
} from "@coreui/react";
import Multiselect from 'multiselect-react-dropdown';
import StepComp from "./StepComp";
import { useForm } from "react-hook-form";

const SearchExpertise = (props) => {
    const [data, setData] = useState(props.reqData.selectedExpertise)
    const {register, handleSubmit, errors, setError, watch} = useForm()
    // useEffect(() => {
    //     setData(props.reqData.selectedExpertise)
    // }, [props.reqData.selectedExpertise])

    const handleSelect = (d) => {
       var objs = []
        d.map((expertise) => {
            objs.push(expertise)
       })
       setData(objs)
    }

    const addExpertise = (d) => {
        var found = false
        data.map((expertise) => {
            if(expertise.childId == d.childId){
                found = true
            }
       })
       if(!found){
           setData([...data, d])
       }
    }

    const onSubmit = (reqData) => {
        props.saveStepData("expertData", {
            selectedExpertise: data,
            requestTitle: reqData.title
        })
        props.incrementStep()
    }

    const prepareChips = () => {
        return props.data.expertise.map((expertise, index) => {
            if(index <=4){
                return <p className="pointer mr-3 popularTag" onClick={() => addExpertise(expertise)} >{expertise.childTitle}</p>
            }
        })
    }

    return (
        <div>
        <div>
        <CRow>
         <CCol md="8">
            <div>
            <p className="heading" >Title for your request</p>
            <div className="mt-2" >
                <div className="mb-4">
                    <CInput defaultValue={props.reqData.requestTitle} style={{height: "45px"}} invalid={errors.title && true} name="title" type="text"
                    placeholder="Write a descriptive title for your project or role request" innerRef={register({required: true})}/>
                    {errors.title &&
                    <CInvalidFeedback>Request title is required</CInvalidFeedback>}
                </div>
            </div>
            </div>
            
            <div>
            <p className="heading" >What expertise do you need support with?</p>
            <div className="mb-4">
             <Multiselect
                 displayValue="childTitle"
                 groupBy="parentTitle"
                 selectedValues={data}
                 onRemove={handleSelect}
                 onSelect={handleSelect}
                 options={props.data.expertise}
                 showCheckbox
                 closeIcon={true}
                 showArrow={true}
                 placeholder="Select or type the functional expertise you need"
                 style={
                     {multiselectContainer: {
                         borderRadius: "10px",
                     },
                     searchBox: {
                     },
                     inputField: { // To change input field position or margin
                         height: "30px",
                         width: "100%"
                     },
                     chips: { // To change css chips(Selected options)
                     },
                     optionContainer: { // To change css for option container
                         height: "900px"
                     },
                     option: { // To change css for dropdown options
                         
                     },
                     groupHeading: { // To chanage group heading style
                         fontWeight: "bold",
                         color: "dimgrey"
                     },
                     optionListContainer: {
                         height: "600px"
                     }
                 }}
             />
             
             </div>
            </div>
             
         </CCol>
         <CCol xs="12"  >
             <div className="d-flex align-items-center mt-2" >
                 <p className="p bold mr-3" >Most Popular: </p>
                 {prepareChips()}
             </div>
         </CCol>
     </CRow>
     <StepComp hideBack={true} decrementStep={props.decrementStep} onSubmit={handleSubmit(onSubmit)}  />
    </div>
    </div>
    )
}

export default SearchExpertise