export const logoNegative = ['408 80', `
  <title>buildit logo</title>
  <g transform="matrix(1,0,0,1,-56.5,-22.2991)">
        <g id="Artboard1" transform="matrix(0.545588,0,0,0.480861,-13.8809,-2.22487)">
            <rect x="129" y="51" width="680" height="188" style="fill:none;"/>
            <g transform="matrix(4.48312,0,0,5.08658,134.761,82.3079)">
                <path d="M0,15.609L0.856,12.502C3.14,13.252 5.676,13.627 8.465,13.627C11.923,13.627 13.652,12.949 13.652,11.593C13.652,11.103 13.411,10.728 12.93,10.466C12.449,10.205 11.824,10.028 11.054,9.936C10.284,9.844 9.433,9.742 8.501,9.63C7.568,9.519 6.632,9.407 5.692,9.294C4.751,9.182 3.894,8.963 3.12,8.636C2.345,8.31 1.716,7.788 1.236,7.07C0.754,6.353 0.514,5.456 0.514,4.379C0.514,3.596 0.644,2.891 0.905,2.263C1.166,1.635 1.529,1.117 1.994,0.709C2.459,0.302 2.969,-0.041 3.523,-0.318C4.078,-0.595 4.701,-0.803 5.395,-0.942C6.088,-1.081 6.74,-1.179 7.352,-1.236C7.964,-1.293 8.604,-1.321 9.273,-1.321C12.306,-1.321 14.908,-0.857 17.077,0.073L16.27,2.96C15.471,2.715 14.847,2.536 14.398,2.422C13.949,2.308 13.301,2.194 12.453,2.079C11.605,1.966 10.683,1.908 9.689,1.908C6.459,1.908 4.844,2.496 4.844,3.673C4.844,4.092 5.032,4.43 5.409,4.688C5.785,4.946 6.287,5.108 6.912,5.175C7.538,5.243 8.241,5.318 9.021,5.402C9.801,5.486 10.601,5.572 11.421,5.659C12.242,5.747 13.043,5.911 13.826,6.148C14.608,6.386 15.309,6.682 15.929,7.034C16.548,7.387 17.045,7.912 17.42,8.609C17.795,9.307 17.983,10.139 17.983,11.107C17.983,14.989 14.908,16.93 8.759,16.93C5.138,16.93 2.218,16.49 0,15.609" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,268.793,77.0799)">
                <path d="M0,17.958C-2.789,17.958 -5.028,17.277 -6.716,15.915C-8.404,14.553 -9.248,12.543 -9.248,9.884L-9.248,0L-4.551,0L-4.551,10.129C-4.551,11.532 -4.106,12.608 -3.217,13.358C-2.328,14.109 -1.264,14.484 -0.024,14.484C1.215,14.484 2.271,14.109 3.144,13.358C4.016,12.608 4.453,11.532 4.453,10.129L4.453,0L9.175,0L9.175,9.884C9.175,12.543 8.339,14.553 6.667,15.915C4.995,17.277 2.773,17.958 0,17.958" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,509.289,166.93)">
                <path d="M0,-17.664L0,-3.303L11.181,-3.303L11.181,0L-4.795,0L-4.795,-17.664L0,-17.664Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(-4.48312,0,0,5.08658,1199.49,-2750.68)">
                <rect x="136.194" y="555.925" width="4.795" height="17.664" style="fill:rgb(0,47,94);"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,623.667,166.93)">
                <path d="M0,-17.664L10.598,-5.917L10.598,-17.664L15.027,-17.664L15.027,0L10.598,0L-0.24,-11.757L-0.24,0L-4.668,0L-4.668,-17.664L0,-17.664Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,374.104,120.107)">
                <path d="M0,0.001L0,0L0,0.001Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(0,5.08658,4.48312,0,374.107,120.111)">
                <path d="M-0.001,-0.001L0.001,-0.001" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.8px;"/>
            </g>
            <g transform="matrix(0,5.08658,4.48312,0,397.636,162.021)">
                <path d="M0.27,-0.125L0.277,-0.125C0.227,0.009 0.174,0.141 0.119,0.27C0.118,0.268 0.118,0.267 0.117,0.266C0.171,0.138 0.221,0.008 0.27,-0.125" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,-0.00394997,-0.00348136,-5.08658,374.003,215.609)">
                <path d="M-0.004,0.002L5.147,0.002L5.155,9.753C3.69,9.232 1.981,8.964 0.003,8.941L-0.004,0.002Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,397.078,127.12)">
                <path d="M0,7.131C0.132,7.082 0.262,7.032 0.39,6.979C0.391,6.98 0.392,6.98 0.394,6.981C1.305,6.596 2.108,6.101 2.807,5.491C4.025,4.429 4.889,3.249 5.393,1.963C5.746,1.012 5.922,-0.022 5.922,-1.139C5.922,-3.073 5.386,-4.764 4.315,-6.212C3.29,-5.969 2.441,-5.515 1.781,-4.85C1.093,-4.157 0.787,-3.389 0.662,-2.432C0.743,-2.021 0.784,-1.591 0.784,-1.139C0.784,-0.316 0.651,0.436 0.386,1.119C0.384,1.114 0.382,1.108 0.38,1.103C0.127,1.794 -0.252,2.392 -0.766,2.919C-1.787,3.966 -3.298,4.55 -5.151,4.621L-5.151,4.63C-5.251,4.633 -5.352,4.635 -5.454,4.635C-7.445,4.635 -8.982,4.089 -10.066,2.996C-11.06,1.996 -11.597,0.755 -11.68,-0.724L-11.68,-1.139L-11.693,-1.139C-11.693,-2.803 -11.151,-4.18 -10.066,-5.273C-8.982,-6.366 -7.445,-6.913 -5.454,-6.913C-4.829,-6.913 -4.249,-6.857 -3.714,-6.749C-3.349,-7.199 -2.933,-7.629 -2.465,-8.039C-1.809,-8.614 -1.06,-9.095 -0.22,-9.484C-1.715,-10.004 -3.459,-10.264 -5.454,-10.264C-9.092,-10.264 -11.897,-9.4 -13.871,-7.671C-15.844,-5.942 -16.831,-3.765 -16.831,-1.139L-16.831,17.395L-11.68,17.39L-11.68,6.81C-9.983,7.594 -7.909,7.987 -5.454,7.987C-5.353,7.987 -5.251,7.986 -5.151,7.985C-3.155,7.959 -1.447,7.679 0,7.138L0,7.131Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,462.72,154.779)">
                <path d="M0,-13.109C-1.221,-14.178 -2.76,-14.917 -4.618,-15.325C-5.759,-15.549 -7.057,-15.658 -8.536,-15.658C-12.098,-15.658 -14.877,-14.805 -16.798,-13.123C-17.502,-12.506 -18.084,-11.839 -18.542,-11.128C-19.376,-9.8 -19.793,-8.283 -19.793,-6.576L-19.793,-1.283C-18.051,-1.35 -16.689,-1.874 -15.742,-2.844C-15.121,-3.481 -14.716,-4.237 -14.507,-5.16C-14.577,-5.481 -14.622,-5.815 -14.642,-6.162L-14.642,-6.577L-14.655,-6.577C-14.655,-7.368 -14.531,-8.093 -14.286,-8.755C-14.093,-9.446 -13.759,-10.051 -13.195,-10.619C-12.517,-11.302 -11.663,-11.781 -10.646,-12.054C-10.649,-12.059 -10.653,-12.063 -10.656,-12.067C-9.987,-12.255 -9.241,-12.35 -8.416,-12.35C-6.427,-12.35 -4.889,-11.804 -3.805,-10.711C-2.72,-9.618 -2.178,-8.24 -2.178,-6.576C-2.178,-4.913 -2.72,-3.534 -3.805,-2.442C-4.889,-1.349 -6.427,-0.803 -8.416,-0.803C-9.053,-0.803 -9.642,-0.86 -10.185,-0.972C-10.574,-0.493 -11.021,-0.033 -11.526,0.407C-12.148,0.95 -12.847,1.407 -13.624,1.779C-12.136,2.292 -10.401,2.549 -8.416,2.549C-4.779,2.549 -1.974,1.689 0,-0.032C1.973,-1.752 2.96,-3.934 2.96,-6.576C2.96,-9.203 1.973,-11.38 0,-13.109" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,790.516,155.235)">
                <path d="M0,-13.065C-1.974,-14.794 -4.779,-15.658 -8.416,-15.658C-12.054,-15.658 -14.859,-14.794 -16.833,-13.065C-18.806,-11.336 -19.793,-9.159 -19.793,-6.533C-19.793,-3.89 -18.806,-1.709 -16.833,0.012C-14.859,1.733 -12.054,2.593 -8.416,2.593C-4.779,2.593 -1.974,1.733 0,0.012C1.973,-1.709 2.96,-3.89 2.96,-6.533C2.96,-9.159 1.973,-11.336 0,-13.065M-3.805,-2.398C-4.889,-1.305 -6.427,-0.759 -8.416,-0.759C-10.406,-0.759 -11.943,-1.305 -13.028,-2.398C-14.113,-3.491 -14.655,-4.869 -14.655,-6.533C-14.655,-8.196 -14.113,-9.575 -13.028,-10.667C-11.943,-11.76 -10.406,-12.307 -8.416,-12.307C-6.427,-12.307 -4.889,-11.76 -3.805,-10.667C-2.72,-9.575 -2.177,-8.196 -2.177,-6.533C-2.177,-4.869 -2.72,-3.491 -3.805,-2.398" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,432.719,197.592)">
                <path d="M0,3.549C-0.507,3.549 -1.056,3.367 -1.434,3.114L-1.099,2.608C-0.749,2.829 -0.357,2.946 -0.007,2.946C0.457,2.946 0.678,2.809 0.678,2.569C0.678,2.31 0.35,2.187 -0.193,1.998C-0.999,1.713 -1.292,1.466 -1.292,0.928C-1.292,0.318 -0.764,0 -0.014,0C0.457,0 0.921,0.156 1.256,0.377L0.935,0.889C0.628,0.695 0.321,0.597 -0.036,0.597C-0.392,0.597 -0.549,0.727 -0.549,0.909C-0.549,1.077 -0.421,1.201 0.143,1.402C0.978,1.694 1.406,1.901 1.406,2.543C1.406,3.296 0.735,3.549 0,3.549" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,453.32,198.317)">
                <path d="M0,3.335L0,3.011C-0.186,3.257 -0.557,3.406 -0.942,3.406C-1.812,3.406 -2.276,2.887 -2.276,1.882L-2.276,-0.071L-1.541,-0.071L-1.541,1.856C-1.541,2.544 -1.256,2.79 -0.771,2.79C-0.278,2.79 0,2.472 0,1.921L0,-0.071L0.735,-0.071L0.735,3.335L0,3.335Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,468.964,203.599)">
                <path d="M0,2.368C-0.378,2.368 -0.735,2.186 -0.942,1.979L-0.942,3.205L-1.67,3.549L-1.67,-1.109L-0.942,-1.109L-0.942,-0.785C-0.671,-1.012 -0.357,-1.181 0.036,-1.181C0.842,-1.181 1.477,-0.649 1.477,0.649C1.477,1.817 0.778,2.368 0,2.368M-0.1,-0.564C-0.45,-0.564 -0.756,-0.35 -0.949,-0.13L-0.949,1.304C-0.785,1.505 -0.442,1.752 -0.071,1.752C0.457,1.752 0.742,1.388 0.742,0.668C0.742,-0.188 0.442,-0.564 -0.1,-0.564" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,487.199,203.599)">
                <path d="M0,2.368C-0.378,2.368 -0.735,2.186 -0.942,1.979L-0.942,3.205L-1.67,3.549L-1.67,-1.109L-0.942,-1.109L-0.942,-0.785C-0.671,-1.012 -0.357,-1.181 0.036,-1.181C0.842,-1.181 1.477,-0.649 1.477,0.649C1.477,1.817 0.778,2.368 0,2.368M-0.1,-0.564C-0.45,-0.564 -0.756,-0.35 -0.949,-0.13L-0.949,1.304C-0.785,1.505 -0.442,1.752 -0.071,1.752C0.457,1.752 0.742,1.388 0.742,0.668C0.742,-0.188 0.442,-0.564 -0.1,-0.564" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(0,5.08658,4.48312,0,508.786,201.386)">
                <path d="M-1.648,-2.382L2.732,-2.382L2.732,-1.648L-1.998,-1.648L-1.648,-2.382Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,512.28,197.957)">
                <path d="M0,4.606L-0.778,4.606L-0.235,3.341L-1.613,0L-0.806,0L-0.193,1.667C-0.078,1.966 0.093,2.472 0.136,2.66C0.2,2.459 0.357,1.972 0.471,1.68L1.106,0L1.884,0L0,4.606Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,523.125,210.395)">
                <path d="M0,1.032C-0.307,1.032 -0.564,0.798 -0.564,0.519C-0.564,0.24 -0.307,0 0,0C0.307,0 0.564,0.24 0.564,0.519C0.564,0.798 0.307,1.032 0,1.032" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,543.695,197.592)">
                <path d="M0,3.549C-0.507,3.549 -1.056,3.367 -1.434,3.114L-1.099,2.608C-0.749,2.829 -0.357,2.946 -0.007,2.946C0.457,2.946 0.678,2.809 0.678,2.569C0.678,2.31 0.35,2.187 -0.193,1.998C-0.999,1.713 -1.292,1.466 -1.292,0.928C-1.292,0.318 -0.764,0 -0.014,0C0.457,0 0.92,0.156 1.256,0.377L0.935,0.889C0.628,0.695 0.321,0.597 -0.036,0.597C-0.393,0.597 -0.549,0.727 -0.549,0.909C-0.549,1.077 -0.421,1.201 0.143,1.402C0.978,1.694 1.406,1.901 1.406,2.543C1.406,3.296 0.735,3.549 0,3.549" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,564.297,198.317)">
                <path d="M0,3.335L0,3.011C-0.186,3.257 -0.557,3.406 -0.942,3.406C-1.812,3.406 -2.276,2.887 -2.276,1.882L-2.276,-0.071L-1.541,-0.071L-1.541,1.856C-1.541,2.544 -1.256,2.79 -0.771,2.79C-0.278,2.79 0,2.472 0,1.921L0,-0.071L0.735,-0.071L0.735,3.335L0,3.335Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,579.941,203.599)">
                <path d="M0,2.368C-0.378,2.368 -0.735,2.186 -0.942,1.979L-0.942,3.205L-1.67,3.549L-1.67,-1.109L-0.942,-1.109L-0.942,-0.785C-0.671,-1.012 -0.357,-1.181 0.036,-1.181C0.842,-1.181 1.477,-0.649 1.477,0.649C1.477,1.817 0.778,2.368 0,2.368M-0.1,-0.564C-0.45,-0.564 -0.756,-0.35 -0.949,-0.13L-0.949,1.304C-0.785,1.505 -0.442,1.752 -0.071,1.752C0.457,1.752 0.742,1.388 0.742,0.668C0.742,-0.188 0.442,-0.564 -0.1,-0.564" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,598.175,203.599)">
                <path d="M0,2.368C-0.378,2.368 -0.735,2.186 -0.942,1.979L-0.942,3.205L-1.67,3.549L-1.67,-1.109L-0.942,-1.109L-0.942,-0.785C-0.671,-1.012 -0.357,-1.181 0.036,-1.181C0.842,-1.181 1.477,-0.649 1.477,0.649C1.477,1.817 0.778,2.368 0,2.368M-0.1,-0.564C-0.45,-0.564 -0.756,-0.35 -0.949,-0.13L-0.949,1.304C-0.785,1.505 -0.442,1.752 -0.071,1.752C0.457,1.752 0.742,1.388 0.742,0.668C0.742,-0.188 0.442,-0.564 -0.1,-0.564" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,615.514,197.592)">
                <path d="M0,3.549C-0.942,3.549 -1.648,2.842 -1.648,1.778C-1.648,0.662 -0.92,0 0.021,0C0.978,0 1.691,0.695 1.691,1.758C1.691,2.887 0.963,3.549 0,3.549M-0.007,0.636C-0.564,0.636 -0.906,1.084 -0.906,1.758C-0.906,2.505 -0.528,2.913 0.029,2.913C0.585,2.913 0.949,2.401 0.949,1.778C0.949,1.084 0.585,0.636 -0.007,0.636" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,636.277,211.325)">
                <path d="M0,-1.921C-0.157,-1.999 -0.321,-2.044 -0.564,-2.044C-1.028,-2.044 -1.299,-1.746 -1.299,-1.168L-1.299,0.778L-2.034,0.778L-2.034,-2.628L-1.299,-2.628L-1.299,-2.304C-1.127,-2.531 -0.813,-2.699 -0.442,-2.699C-0.143,-2.699 0.043,-2.648 0.193,-2.557L0,-1.921Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,648.881,192.018)">
                <path d="M0,4.489C-0.178,4.58 -0.428,4.645 -0.721,4.645C-1.242,4.645 -1.563,4.353 -1.563,3.743L-1.563,1.777L-2.098,1.777L-2.098,1.167L-1.563,1.167L-1.563,0.194L-0.842,-0.156L-0.842,1.167L0.086,1.167L0.086,1.777L-0.842,1.777L-0.842,3.626C-0.842,3.944 -0.728,4.035 -0.457,4.035C-0.264,4.035 -0.05,3.97 0.086,3.899L0,4.489Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,655.055,210.395)">
                <path d="M0,1.032C-0.307,1.032 -0.564,0.798 -0.564,0.519C-0.564,0.24 -0.307,0 0,0C0.307,0 0.564,0.24 0.564,0.519C0.564,0.798 0.307,1.032 0,1.032" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,675.626,197.592)">
                <path d="M0,3.549C-0.507,3.549 -1.056,3.367 -1.434,3.114L-1.099,2.608C-0.749,2.829 -0.357,2.946 -0.007,2.946C0.457,2.946 0.678,2.809 0.678,2.569C0.678,2.31 0.35,2.187 -0.193,1.998C-0.999,1.713 -1.292,1.466 -1.292,0.928C-1.292,0.318 -0.764,0 -0.014,0C0.457,0 0.92,0.156 1.256,0.377L0.935,0.889C0.628,0.695 0.321,0.597 -0.036,0.597C-0.393,0.597 -0.549,0.727 -0.549,0.909C-0.549,1.077 -0.421,1.201 0.143,1.402C0.978,1.694 1.406,1.901 1.406,2.543C1.406,3.296 0.735,3.549 0,3.549" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,696.227,198.317)">
                <path d="M0,3.335L0,3.011C-0.186,3.257 -0.557,3.406 -0.942,3.406C-1.812,3.406 -2.276,2.887 -2.276,1.882L-2.276,-0.071L-1.541,-0.071L-1.541,1.856C-1.541,2.544 -1.256,2.79 -0.771,2.79C-0.278,2.79 0,2.472 0,1.921L0,-0.071L0.735,-0.071L0.735,3.335L0,3.335Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,714.942,210.3)">
                <path d="M0,-1.447C-0.307,-1.732 -0.578,-1.862 -0.913,-1.862C-1.449,-1.862 -1.791,-1.428 -1.791,-0.733C-1.791,-0.065 -1.477,0.415 -0.892,0.415C-0.542,0.415 -0.335,0.272 -0.043,-0.007L0.428,0.415C0.136,0.752 -0.236,1.051 -0.913,1.051C-1.827,1.051 -2.533,0.35 -2.533,-0.714C-2.533,-1.843 -1.805,-2.498 -0.892,-2.498C-0.342,-2.498 0.086,-2.297 0.435,-1.921L0,-1.447Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,731.097,210.3)">
                <path d="M0,-1.447C-0.307,-1.732 -0.578,-1.862 -0.913,-1.862C-1.449,-1.862 -1.791,-1.428 -1.791,-0.733C-1.791,-0.065 -1.477,0.415 -0.892,0.415C-0.542,0.415 -0.335,0.272 -0.043,-0.007L0.428,0.415C0.136,0.752 -0.235,1.051 -0.913,1.051C-1.827,1.051 -2.533,0.35 -2.533,-0.714C-2.533,-1.843 -1.805,-2.498 -0.892,-2.498C-0.342,-2.498 0.086,-2.297 0.435,-1.921L0,-1.447Z" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,750.228,205.417)">
                <path d="M0,0.473L-2.476,0.473C-2.398,1.128 -1.991,1.407 -1.534,1.407C-1.22,1.407 -0.97,1.303 -0.721,1.135L-0.285,1.563C-0.614,1.848 -1.006,2.011 -1.577,2.011C-2.455,2.011 -3.197,1.368 -3.197,0.239C-3.197,-0.915 -2.526,-1.538 -1.556,-1.538C-0.492,-1.538 0.021,-0.753 0.021,0.168C0.021,0.291 0.007,0.402 0,0.473M-1.605,-0.935C-2.098,-0.935 -2.398,-0.623 -2.462,-0.078L-0.721,-0.078C-0.756,-0.545 -0.999,-0.935 -1.605,-0.935" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,759.537,197.592)">
                <path d="M0,3.549C-0.507,3.549 -1.056,3.367 -1.434,3.114L-1.099,2.608C-0.749,2.829 -0.357,2.946 -0.007,2.946C0.457,2.946 0.678,2.809 0.678,2.569C0.678,2.31 0.35,2.187 -0.193,1.998C-0.999,1.713 -1.292,1.466 -1.292,0.928C-1.292,0.318 -0.764,0 -0.014,0C0.457,0 0.92,0.156 1.256,0.377L0.935,0.889C0.628,0.695 0.321,0.597 -0.036,0.597C-0.392,0.597 -0.549,0.727 -0.549,0.909C-0.549,1.077 -0.421,1.201 0.143,1.402C0.978,1.694 1.406,1.901 1.406,2.543C1.406,3.296 0.735,3.549 0,3.549" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.48312,0,0,5.08658,775.181,197.592)">
                <path d="M0,3.549C-0.507,3.549 -1.056,3.367 -1.434,3.114L-1.099,2.608C-0.749,2.829 -0.357,2.946 -0.007,2.946C0.457,2.946 0.678,2.809 0.678,2.569C0.678,2.31 0.35,2.187 -0.193,1.998C-0.999,1.713 -1.292,1.466 -1.292,0.928C-1.292,0.318 -0.764,0 -0.014,0C0.457,0 0.92,0.156 1.256,0.377L0.935,0.889C0.628,0.695 0.321,0.597 -0.036,0.597C-0.392,0.597 -0.549,0.727 -0.549,0.909C-0.549,1.077 -0.421,1.201 0.143,1.402C0.978,1.694 1.406,1.901 1.406,2.543C1.406,3.296 0.735,3.549 0,3.549" style="fill:rgb(0,47,94);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1.07595,0,0,1.22078,-318.595,-345.753)">
                <use xlink:href="#_Image1" x="1024" y="454" width="7px" height="7px"/>
            </g>
        </g>
    </g>
`]


