import React, {useState, useEffect} from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CInvalidFeedback,
  CAlert,
  CInputCheckbox,
  CLabel,
  CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {setPassword} from "../../actions/auth"
import queryString from "query-string";
import { toast } from 'react-toastify';

const ResetPassword = (props) => {

  let params = queryString.parse(props.location.search);

  useEffect(() => {
  },[]);

  const { register, handleSubmit, errors, setError } = useForm();
  const [ loader, setLoader ] = useState(false)
  const [ mainError, setMainError ] = useState(false)

  const onSubmit = (data) => {
      setLoader(true)
      var reqData = {
          code: props.match.params.id,
          password: data.password,
          password_confirmation: data.confirm_password
      }
    props.setPassword(reqData).then(() => {
        toast.success("Password has been reset!")
        setTimeout(() => {
            props.history.push("/login")
        }, 1000);
    }).catch((err) => {
        parseError(err)
        setLoader(false)
    })
  };

  const parseError = (serverErrors) => {
    if(serverErrors.message != undefined){
        setMainError(serverErrors.message)
    }else{
      Object.keys(serverErrors).map((key) => {
        setError(key, {
          message: serverErrors[key]
        });
      })
    }
  }

  return (
    <div className={props.modal != undefined ? "flex-row align-items-center" : "flex-row align-items-center mb-5 mt-5"}>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5" >
              <CCard className="p-2">
                <CCardBody>
                    
                  <CForm onSubmit={handleSubmit(onSubmit)} > 
                    { mainError && <CAlert color="danger">
                      {mainError}
                    </CAlert>}
                    <p className="subHeading bold mb-2">Reset your password</p>
                    <p className="p mb-4">Please set a new password for your account.</p>
                    <CInputGroup className="mb-3 text-left">
                      <CInputGroupPrepend>
                        <CInputGroupText className="customInputIcon">
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput className="customInputLogin" invalid={errors.password && true} name="password" type="password" placeholder="New Password" innerRef={register({ required: {
                          value: true,
                          message: "Password is required"
                      } })} />
                      {errors.password && <CInvalidFeedback>{errors.password.message}</CInvalidFeedback>}
                    </CInputGroup>
                    <CInputGroup className="mb-3 text-left">
                      <CInputGroupPrepend>
                        <CInputGroupText className="customInputIcon">
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput className="customInputLogin" invalid={errors.confirm_password && true} name="confirm_password" type="password" placeholder="Confirm New Password" innerRef={register({ required: true })} />
                      {errors.confirm_password && <CInvalidFeedback>Confirm Password is required.</CInvalidFeedback>}
                    </CInputGroup>

                    <CRow className="mt-5" >
                        <CCol className="text-center" >
                            <CButton color="primary" className="btn200 btn-pill" type="submit" disabled={loader} >{ loader ? "Processing..." :  "Set Password" }</CButton>
                        </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapDispatchToProps = {
  setPassword
};

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));