import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CRow,
    CCol,
    CButton,
    CCard,
    CCardBody,
    CInputCheckbox,
    CInput,
    CForm,
    CInputGroup,
    CInputGroupPrepend,
    CInvalidFeedback,
    CInputGroupText,
    CSelect,
    CInputRadio
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import {useForm} from 'react-hook-form';
import { Country, State, City }  from 'country-state-city';
import { getCountryNameName, STORE } from "../../../../helpers/CommonHelper";
import { updateClientDetails } from "../../../../actions/ExpertActions";

const ClientCompany = props => {
    
    const {register, handleSubmit, errors, setError, watch} = useForm()
    const [error, setDefaultError] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(props.data.company_country)
    const countryListArray = Country.getAllCountries()
    const [data, setData] = useState(props.data)
    const [bSizes, setBSizes] = useState(props.data.bSizes)
    const watchAllFields = watch()

    const onSubmit = (data) => {
        data["business_size"] = bSizes
        // STORE.dispatch(updateClientDetails(data)).then((resp) => {
            props.persistData({
                company_country: data.company_country,
                company_website: data.company_website,
                bSizes: bSizes,
                company_state: data.company_state
            })
            props.updateStep(props.step+1)
        // })
    };

    const handleCheckbox = (checked, childId) => {
        setBSizes(childId)
    }

        return <div>
            <div className="d-flex align-items-center mb-3" >
                <div className="mr-3 yellowBg" style={{width: "70px", height: "70px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                    <p className="mb-0 heading bold" style={{color: "#fff"}} >1</p>
                </div>
                <p className="heading">Your Product & Company - 2 of 2</p>
            </div>
        <CCard className="boxShadow">
            <CCardBody>
                <div>
                    <p className="heading"  >Company - Tell us about your business</p>
                    <p className="subHeading bold mt-3 mb-4" >Where is your business located ?</p>
                    <CForm onSubmit={handleSubmit(onSubmit)}>
                        {error && <CAlert color="danger">
                            {error}
                        </CAlert>}
                        <CRow>
                            <CCol>
                                <div className="mb-4">
                                    <p className="mb-2 bold" >Country</p>
                                    <CSelect defaultValue={data.company_country} custom size="md"
                                        onChange={(val) => setSelectedCountry(val.target.value)}
                                        name="company_country" style={{maxWidth: "100%"}}
                                        className="cutomSelect d-block"
                                        invalid={errors.company_country && true}
                                        innerRef={register({required: true})}
                                        >
                                        <option value="">Select Country</option>
                                        {countryListArray.map((country) => (
                                            <option value={country.isoCode}>{ getCountryNameName(country.name)}</option>
                                        ))}
                                    </CSelect>
                                    {errors.company_country &&
                                    <CInvalidFeedback>Country is required</CInvalidFeedback>}
                                </div>
                            </CCol>
                            <CCol>
                                <div className="mb-4">
                                    <p className="mb-2 bold" >State/Department/Province</p>
                                    <CSelect defaultValue={data.company_state} custom size="md"
                                        name="company_state" style={{maxWidth: "100%"}}
                                        className="cutomSelect d-block"
                                        invalid={errors.company_state && true}
                                        innerRef={register({required: State.getStatesOfCountry(selectedCountry).length > 0})}
                                        disabled={State.getStatesOfCountry(selectedCountry).length == 0}
                                        >
                                        <option value="">Select State</option>
                                        {State.getStatesOfCountry(selectedCountry).map((state) => (
                                            <option value={state.isoCode}>{state.name}</option>
                                        ))}
                                    </CSelect>
                                    {errors.company_state &&
                                    <CInvalidFeedback>State is required</CInvalidFeedback>}
                                </div>
                            </CCol>
                        </CRow>
                        <div className="mt-4" >
                            <p className="subHeading bold" >Business size</p>
                            <CRow>
                                <CCol md="2" >
                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                        <CInputRadio name="b_size" className="mr-2 mt-0" 
                                        onClick={(e) => handleCheckbox(e.target.checked, "individual")}
                                        defaultChecked={bSizes.includes("individual") ? true : false} 
                                        />
                                        <p className="mb-0 lead" >Individual</p>
                                    </div>
                                </CCol>
                                <CCol md="2">
                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                        <CInputRadio name="b_size" className="mr-2 mt-0"
                                         onClick={(e) => handleCheckbox(e.target.checked, "<10")}
                                         defaultChecked={bSizes.includes("<10") ? true : false}
                                        />
                                        <p className="mb-0 lead" >{"<10"}</p>
                                    </div>
                                </CCol>
                                <CCol md="2">
                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                        <CInputRadio name="b_size" className="mr-2 mt-0"
                                        onClick={(e) => handleCheckbox(e.target.checked, "11-100")}
                                        defaultChecked={bSizes.includes("11-100") ? true : false}
                                        />
                                        <p className="mb-0 lead" >11-100</p>
                                    </div>
                                </CCol>
                                <CCol md="2">
                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                        <CInputRadio name="b_size" className="mr-2 mt-0"
                                        onClick={(e) => handleCheckbox(e.target.checked, "101-500")}
                                        defaultChecked={bSizes.includes("101-500") ? true : false}
                                        />
                                        <p className="mb-0 lead" >101-500</p>
                                    </div>
                                </CCol>
                                <CCol md="2">
                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                        <CInputRadio name="b_size" className="mr-2 mt-0"
                                         onClick={(e) => handleCheckbox(e.target.checked, "ABOVE_500")}
                                         defaultChecked={bSizes.includes("ABOVE_500") ? true : false}
                                        />
                                        <p className="mb-0 lead" >Above 500</p>
                                    </div>
                                </CCol>

                            </CRow>
                        </div>

                        <div className="mt-4" >
                            <p className="subHeading bold" >Company  website</p>
                            <CRow>
                                <CCol md="6" >
                                    <div className="mb-4">
                                        <CInput defaultValue={data.company_website} className="customInput" invalid={errors.company_website && true} name="company_website" type="text"
                                        placeholder="domain.com" innerRef={register({})}/>
                                        {errors.company_website &&
                                        <CInvalidFeedback>{errors.company_website.message}</CInvalidFeedback>}
                                    </div>
                                </CCol>
                            </CRow>
                        </div>

                        <CRow className="mt-5">
                            <CCol className="d-flex align-items-center justify-content-end">
                                <CButton
                                    size="lg"
                                    onClick={() => {
                                        props.persistData({
                                            ...watchAllFields,
                                            ...{bSizes: bSizes}
                                        })
                                        props.updateStep(props.step-1)
                                        }
                                    }
                                    className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                                    <CIcon size="xl"name="cil-arrow-left"/>
                                    <p className="mb-0 lead" >Back</p>
                                </CButton>
                                <CButton
                                    size="lg"
                                    type="submit"
                                    // onClick={() => props.updateStep(props.step+1)}
                                    className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                                    <p className="mb-0 lead" >Next Step</p>
                                    <CIcon size="xl"name="cil-arrow-right"/>
                                </CButton>
                            </CCol>
                        </CRow>

                    </CForm>
                </div>

                
            </CCardBody>
        </CCard>
        </div>
}

export default (withRouter(ClientCompany));
