import React, {useState, useEffect} from 'react'
import {
    CAlert
} from '@coreui/react'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import store from '../../store';
import {getSiteMeta} from '../../actions/GeneralActions';
import Register from './Register';
import RegisterBeta from './RegisterBeta';

const RegisterToggle = (props) => {

    const [registerLoader, setRegisterLoader] = useState(false)
    const [registerError, setRegisterError] = useState(null)
    const [showNormal, setShowNormal] = useState(true)

    useEffect(() => {
        // store.dispatch(getSiteMeta()).then((data) => {
        //     var isNormal = data.register == "normal" ? true : false
        //     setShowNormal(isNormal)
        //     setRegisterLoader(false)
        // }).catch((err) => {
        //     setRegisterError(err)
        //     // setRegisterLoader(false)
        // })

    }, []);

    return (
        <div className=""
             style={{
                 minHeight: '100%',
                 display: 'flex',
                 flex: 1,
                 alignItems: 'center',
                 justifyContent: 'center',
             }}>
            {registerError && <CAlert color="danger">
                {registerError}
            </CAlert>}
            {registerLoader ? <div>
                <span className="spinner-border" role="status"></span>
            </div> : <div>
                {showNormal ? <Register/> : <RegisterBeta/>}
            </div>}
        </div>
    )
}


export default connect(null, null)(withRouter(RegisterToggle));
