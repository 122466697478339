import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {
    CForm,
    CAlert,
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButton
} from "@coreui/react";
import 'react-phone-input-2/lib/style.css'
import "react-datepicker/dist/react-datepicker.css";

const CallConfirmed = (props) => {
    const {expert} = props
    const [error, setError] = useState("")
    const history = useHistory()

    useEffect(() => {
    }, [])

    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >{props?.client ? "4" : "5"}</p>
                    </div>
                    <p className="heading">Confirmed Appointment</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                <CRow className="mb-5">
                    <CCol>
                        <CRow>
                            <CCol xs="12">
                                <p className="heading mb-3 bold">Your call is confirmed!</p>
                                <div className="" >
                                    <p className="lead mb-1" >
                                    You are scheduled for a call with our team. A calendar invite has been sent to your email.
                                    </p>
                                    <p className="lead" >
                                        We look forward to talking with you.
                                    </p>
                                    {!expert && <p className="lead" >
                                        If you need to make another request, you can start below
                                    </p>}
                                </div>
                                {/* <div className="mt-4" >
                                    <CButton onClick={() => props.handleResched()} color="primary" className="btn-pill btn200" >Re-schedule Call</CButton>
                                </div> */}
                            </CCol>
                        </CRow>

                        {!expert && <CRow className="mt-5">
                            <CCol className="d-flex align-items-center justify-content-end">
                                <CButton
                                    size="lg"
                                    onClick={() => history.push("/client/onboard")}
                                    className="btn100 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                                    {/* <CIcon size="xl"name="cil-arrow-left"/> */}
                                    <p className="mb-0 lead" >Start A New Request</p>
                                </CButton>
                                <CButton
                                    size="lg"
                                    onClick={() => history.push("/dashboard")}
                                    className="btn100 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                                    <p className="mb-0 lead" >Close</p>
                                    {/* <CIcon size="xl"name="cil-arrow-right"/> */}
                                </CButton>
                            </CCol>
                        </CRow>}
                    </CCol>
                </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

export default withRouter(CallConfirmed);
