import React from 'react'
import { ToastContainer } from 'react-toastify';
import ExpertHeader from './ExpertHeader';
import ExpertFooter from './ExpertFooter';
import ExpertContent from './ExpertContent';

const ExpertLayout = () => {
  return (
    <div className="c-app c-default-layout" style={{background: "#4F6BFB"}} >
      {/* <TheSidebar className="sidebar-hidden" /> */}
      <div className="c-wrapper"  >
        <ExpertHeader/>
        <div className="c-body">
          <ExpertContent />
        </div>
        <ExpertFooter/>
        <ToastContainer position="bottom-center" autoClose={3000} />
      </div>
    </div>
  )
}

export default ExpertLayout
