import React from "react";
import {
    CRow,
    CCol,
} from "@coreui/react";
import StepComp from "./StepComp";

const Review = (props) => {

    const onSubmit = () => {
        props.submitData()
    }

    return (
        <div>
            <p className="heading"  >Summary and Create Role</p>
            <CRow >
                <CCol md="6" className="p-3 ml-4" style={{border: "1px solid lightgrey", borderRadius: "5px", background: "rgb(248, 248, 248)"}} >
                    <p className="lead bold mr-5" >Summary</p>
                    <div className="d-flex align-items-center"  >
                    <div>
                        <p className="lead mr-5" >Talent Type</p>
                        <p className="lead mr-5">Skills</p>
                        <p className="lead mr-5">Location</p>
                        <p className="lead mr-5">Rate</p>
                        <p className="lead mr-5">Hours</p>
                    </div>
                    <div>
                        <p className="lead mr-5">{(props.reqData.selectedExpertise.map((v) => {
                            return <span>{v.childTitle} {" "}</span>
                        }))}</p>
                        <p className="lead mr-5">{(props.reqData.selectedSkills.map((v) => {
                            return <span>{v.childTitle} {" "}</span>
                        }))}</p>
                        <p className="lead mr-5">{props.reqData.details.expert_location}</p>
                        <p className="lead mr-5">${props.reqData.details.expert_max_budget} / Hour</p>
                        <p className="lead mr-5">{props.reqData.details.expert_hours_week} hours per week</p>
                    </div>
                </div>
                </CCol>
            </CRow>
            <StepComp onSubmit={onSubmit} decrementStep={props.decrementStep}  />
        </div>
    )
}

export default Review