import React, {useState} from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CInvalidFeedback,
    CAlert, CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {useForm} from 'react-hook-form';
import {getIntendedUrl, setIntendedUrl} from '../../helpers/auth';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {register, socialLogin} from "../../actions/auth"
import supplyChain from "../../assets/images/supplyChain.png"
import {Divider} from '../../components/Divider';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import {FB_ID, GOOGLE_ID} from '../../helpers/CommonHelper';
import googleIcon from "../../assets/images/icons/google_login_icon.png"
import fbIcon from "../../assets/images/icons/fb_login_icon.png"
import queryString from "query-string";
import store from '../../store';
import VerifyAccountModal from '../../modals/VerifyAccountModal';

const Register = (props) => {

    let params = queryString.parse(props.location.search);
    const {register, handleSubmit, errors, setError} = useForm();
    const [loader, setLoader] = useState(false)
    const [error, setDefaultError] = useState(false)
    const [fbLoader, setFbLoader] = useState(false)
    const [verification, setVerification] = useState(false)
    const [googleLoader, setGoogleLoader] = useState(false)
    const [verifyMessage, setVerifyMessage] = useState()

    const parseError = (serverErrors) => {
        if (serverErrors.message != undefined) {
            setDefaultError(serverErrors.message)
        } else {
            Object.keys(serverErrors).map((key) => {
                setError(key, {
                    message: serverErrors[key][0]
                });
            })
        }
    }

    const onSubmit = (data) => {
        if (params.ref_id != undefined) {
            data["ref_id"] = params.ref_id
        }
        setLoader(true)
        data["userType"] = "expert"
        props.register(data).then(async (resp) => {
            if(resp?.message == "ACC_FOUND"){
                setVerifyMessage("We already found an account associated with your email. We sent an verification code to your email.")
            }
            setLoader(false)
            // TOGGLE VERIFICATION MODAL
            setVerification(true)
        }).catch((e) => {
            setLoader(false)
            parseError(e)
        })
    };

    const responseFacebook = (resp) => {
        var reqData = null
        if (resp.accessToken != undefined) {

            if (params.ref_id != undefined) {
                reqData = {
                    accessToken: resp.accessToken,
                    type: "facebook",
                    ref_id: params.ref_id
                }
            } else {
                reqData = {
                    accessToken: resp.accessToken,
                    type: "facebook"
                }
            }
            reqData["userType"] = "expert"
            props.socialLogin(reqData).then(() => {
                setFbLoader(true)
                getIntendedUrl().then(async (url) => {
                    // CHECK IF USER INVITED FOR A PROJECT
                    if (params.pid) {
                        await props.joinProject({pid: params.pid})
                    }
                    // reset intended URL in local forag
                    setIntendedUrl("/")
                    window.location.href = "/expertSteps"
                }).catch((e) => {
                    setFbLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    const responseGoogle = (resp) => {
        var reqData = null
        if (resp.accessToken != undefined) {
            if (params.ref_id != undefined) {
                reqData = {
                    accessToken: resp.accessToken,
                    profileObj: resp.profileObj,
                    type: "google",
                    ref_id: params.ref_id
                }
            } else {
                reqData = {
                    accessToken: resp.accessToken,
                    profileObj: resp.profileObj,
                    type: "google"
                }
            }
            
            props.socialLogin({
                accessToken: resp.accessToken,
                profileObj: resp.profileObj,
                type: "google",
                userType : "expert"
            }).then(() => {
                setGoogleLoader(true)
                getIntendedUrl().then(async (url) => {
                    // CHECK IF USER INVITED FOR A PROJECT
                    if (params.pid) {
                        await props.joinProject({pid: params.pid})
                    }
                    // reset intended URL in local forag
                    setIntendedUrl("/")
                    window.location.href = "/expertSteps"
                }).catch((e) => {
                    setGoogleLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    const handleLoginLink = () => {
        props.history.push("/expert/login")
    }

    return (
        <div
            className={props.modal != undefined ? "flex-row align-items-center" : "flex-row align-items-center mb-5 mt-5"}>
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="12" lg="6" className="leftLoginBox d-lg-flex align-items-center flex-column d-none"
                          style={{padding: "50px"}}>
                        <div>
                            <p className="mb-3 heading">Sign up as a Product Expert or Supply Chain Consultant</p>
                            <p className="lead mb-5 subHeading">
                            Join our network to get access to exciting projects that fit your expertise and interest.
                            </p>
                        </div>
                        <div>
                            <img className="img-fluid" src={supplyChain}/>
                        </div>
                        <p className="lead mb-0" >We provide great projects, attractive rates, flexibility and professional support. </p>
                    </CCol>
                    <CCol md="12" lg="6" className="rightLoginBox">
                        {/* <div className="text-center" style={{marginBottom: 30}} >
              <img src={Logo} width="300" />
              </div> */}
                        {props.modal && <CIcon name="cil-x-circle" size="xl" className="closeIcon pointer"
                                               onClick={() => props.setModal()}/>}
                        <CCardGroup>
                            <CCard className="p-4 loginCard">
                                <CCardBody className="p-0">
                                    <CForm onSubmit={handleSubmit(onSubmit)}>
                                        {error && <CAlert color="danger">
                                            {error}
                                        </CAlert>}
                                        <p className="heading mb-5">Create Account</p>

                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.username && true} name="username" type="text"
                                                    placeholder="Username"
                                                    innerRef={register({ required: {value: true, message: "Username is required"}, pattern: {
                                                        value: /^[/^[A-Za-z0-9_.-]+$/i,
                                                        message: "Invalid username only use dot, hyphen and undersocre"
                                                        }})}
                                                    />
                                            {errors.username &&
                                            <CInvalidFeedback>{errors.username.message}</CInvalidFeedback>}
                                        </CInputGroup>

                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.email && true} name="email" type="text"
                                                    placeholder="Email" innerRef={register({
                                                required: {value: true, message: "Email is required"}, pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}/>
                                            {errors.email &&
                                            <CInvalidFeedback>{errors.email.message}</CInvalidFeedback>}
                                        </CInputGroup>
                                        <CInputGroup className="mb-3 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-lock-locked"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.password && true} name="password" type="password"
                                                    placeholder="Password" innerRef={register(
                                                {
                                                    required: {value: true, message: "Password is required"},
                                                    minLength: {value: 8, message: "Password must be 8 characters long"}
                                                }
                                            )}/>
                                            {errors.password &&
                                            <CInvalidFeedback>{errors.password.message}</CInvalidFeedback>}
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="12">
                                                <div>
                                                    <CButton className="px-4 btn-pill mb-2 btn200 mt-4" color="primary"
                                                             type="submit" disabled={loader} >{loader ? "Registering..." : "Register"}</CButton>
                                                </div>
                                                <div>
                                                    <CButton className="mb-3 lead p" color="link" onClick={() => {
                                                        props.modal != undefined ? props.setModalType(1) : handleLoginLink()
                                                    }}>Have an account? <b
                                                        className="yellowText bold">Log-in</b></CButton>
                                                </div>
                                                <Divider className="mb-5"/>

                                                <div>
                                                    <p className="subHeading lead bold mb-4">Register With</p>
                                                    <CRow>
                                                        <CCol lg="12" xl="6">
                                                            <FacebookLogin
                                                                appId={FB_ID}
                                                                autoload={false}
                                                                callback={(resp) => responseFacebook(resp)}
                                                                onFailure={(err) => console.log(err)}
                                                                render={renderProps => (
                                                                    <CButton style={{
                                                                        background: "#EBF1FC",
                                                                        color: "#335DA7",
                                                                        fontWeight: "bold"
                                                                    }} onClick={renderProps.onClick}
                                                                             className="px-4 btn-pill mb-2 btn200"
                                                                             variant="outline" disabled={fbLoader}>
                                                                        <img src={fbIcon} className="mr-2 middle"/>
                                                                        <span
                                                                            className="middle">{fbLoader ? "Processing..." : "Facebook"}</span></CButton>
                                                                )}
                                                            />
                                                        </CCol>

                                                        <CCol lg="12" xl="6">
                                                            <GoogleLogin
                                                                clientId={GOOGLE_ID}
                                                                render={renderProps => (
                                                                    <CButton style={{
                                                                        background: "#FBEEF0",
                                                                        color: "#ED0028",
                                                                        fontWeight: "bold"
                                                                    }} onClick={renderProps.onClick}
                                                                             className="px-4 btn-pill mb-2 btn200"
                                                                             variant="outline" disabled={googleLoader}>
                                                                        <img src={googleIcon} className="mr-2 middle"/>
                                                                        <span
                                                                            className="middle">{googleLoader ? "Processing..." : "Google"}</span>
                                                                    </CButton>
                                                                )}
                                                                onSuccess={(resp) => responseGoogle(resp)}
                                                                onFailure={(err) => console.log(err)}
                                                                cookiePolicy={'single_host_origin'}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                </div>

                                                <p className="lead mt-4 p">By signing in to Supplino, you agree to
                                                    our <CLink target="_blank" download
                                                               to={{pathname: "https://buildit.studio/launch/terms-and-conditions/"}}><u
                                                        className="yellowText bold">Terms</u></CLink> and <CLink
                                                        target="_blank" download
                                                        to={{pathname: "https://buildit.studio/launch/privacy-policy/"}}><u
                                                        className="yellowText bold">Privacy Policy</u></CLink>.</p>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>

                    </CCol>
                </CRow>
            </CContainer>
            {verification && <VerifyAccountModal msg={verifyMessage} modal={verification} toggle={setVerification} />}
        </div>
    )
}

const mapDispatchToProps = {
    register,
    socialLogin
};

export default connect(null, mapDispatchToProps)(withRouter(Register));
