import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CInvalidFeedback,
    CAlert, CLink
} from '@coreui/react'
import {useForm} from 'react-hook-form';
import CIcon from '@coreui/icons-react'
import VerifyAccountModal from "../../../../modals/VerifyAccountModal";
import {register, socialLogin} from "../../../../actions/auth"
import { FB_ID, GOOGLE_ID, STORE } from "../../../../helpers/CommonHelper";
import {connect} from 'react-redux'
import GoogleLogin from "react-google-login";
import googleIcon from "../../../../assets/images/icons/google_login_icon.png"
import fbIcon from "../../../../assets/images/icons/fb_login_icon.png"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { getIntendedUrl, setIntendedUrl } from "../../../../helpers/auth";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const ClientRegister = props => {

    const {register, handleSubmit, errors, setError} = useForm();
    const [loader, setLoader] = useState(false)
    const [error, setDefaultError] = useState(false)
    const [fbLoader, setFbLoader] = useState(false)
    const [verification, setVerification] = useState(false)
    const [googleLoader, setGoogleLoader] = useState(false)
    const [verifyMessage, setVerifyMessage] = useState()
    const sendDataToGTM = useGTMDispatch()

    useEffect(() => {
        
    }, []);

    const parseError = (serverErrors) => {
        if (serverErrors.message != undefined) {
            setDefaultError(serverErrors.message)
        } else {
            Object.keys(serverErrors).map((key) => {
                setError(key, {
                    message: serverErrors[key][0]
                });
            })
        }
    }

    const onSubmit = (data) => {
        setLoader(true)
        data["userType"] = "client"
         props.register(data).then(async (resp) => {
             if(resp?.message == "ACC_FOUND"){
                 setVerifyMessage("We already found an account associated with your email. We sent an verification code to your email.")
             }
            setLoader(false)
            // TOGGLE VERIFICATION MODAL
            setVerification(true)
        }).catch((e) => {
            setLoader(false)
            parseError(e)
        })
    }

    const responseFacebook = (resp) => {
        var reqData = null
        if (resp.accessToken != undefined) {
            reqData = {
                accessToken: resp.accessToken,
                type: "facebook"
            }
            reqData["userType"] = "client"
            props.socialLogin(reqData).then(() => {
                setFbLoader(true)
                getIntendedUrl().then(async (url) => {
                    // reset intended URL in local forag
                    sendDataToGTM({ event: 'clientRegister', value: true })
                    setIntendedUrl("/dashboard")
                    window.location.href = "/dashboard"
                }).catch((e) => {
                    setFbLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    const responseGoogle = (resp) => {
        var reqData = null
        if (resp.accessToken != undefined) {
            reqData = {
                accessToken: resp.accessToken,
                profileObj: resp.profileObj,
                type: "google"
            }
            props.socialLogin({
                accessToken: resp.accessToken,
                profileObj: resp.profileObj,
                type: "google",
                userType : "client"
            }).then(() => {
                setGoogleLoader(true)
                getIntendedUrl().then(async (url) => {
                    // reset intended URL in local forag
                    sendDataToGTM({ event: 'clientRegister', value: true })
                    setIntendedUrl("/dashboard")
                    window.location.href = "/dashboard"
                }).catch((e) => {
                    setGoogleLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    return (
        <>
            <CCard className="mt-5 pl-4 pr-4" >
                <CCardBody >
                    <p className="bold text-center" style={{marginBottom: "4rem", fontSize: "2.5rem"}} >Let’s get started!</p>

                    <CForm onSubmit={handleSubmit(onSubmit)}>
                        {error && <CAlert color="danger">
                            {error}
                        </CAlert>}
                        <CRow>
                            <CCol xs="12" md="8" className="offset-md-2" >
                                <CRow>
                                    <CCol xs="12" md="6" >  
                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.username && true} name="username" type="text"
                                                    placeholder="Username" 
                                                    innerRef={register({ required: {value: true, message: "Username is required"}, pattern: {
                                                        value: /^[/^[A-Za-z0-9_.-]+$/i,
                                                        message: "Invalid username only use dot, hyphen and undersocre"
                                                        }})}
                                                        />
                                            {errors.username &&
                                            <CInvalidFeedback>{errors.username.message}</CInvalidFeedback>}
                                        </CInputGroup>

                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.email && true} name="email" type="text"
                                                    placeholder="Email" innerRef={register({
                                                required: {value: true, message: "Email is required"}, pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}/>
                                            {errors.email &&
                                            <CInvalidFeedback>{errors.email.message}</CInvalidFeedback>}
                                        </CInputGroup>

                                    </CCol>

                                    <CCol xs="12" md="6" >
                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-briefcase"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.company && true} name="company" type="text"
                                                    placeholder="Company" 
                                                    innerRef={register()}/>
                                            {errors.company &&
                                            <CInvalidFeedback>{errors.company.message}</CInvalidFeedback>}
                                        </CInputGroup>

                                        <CInputGroup className="mb-3 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-lock-locked"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.password && true} name="password" type="password"
                                                    placeholder="Password" innerRef={register(
                                                {
                                                    required: {value: true, message: "Password is required"},
                                                    minLength: {value: 8, message: "Password must be 8 characters long"}
                                                }
                                            )}/>
                                            {errors.password &&
                                            <CInvalidFeedback>{errors.password.message}</CInvalidFeedback>}
                                        </CInputGroup>
                                    </CCol>
                                </CRow>
                                <CButton
                                    type="submit"
                                    disabled={loader} 
                                    className="mt-5 btn-block btn-pill btn-primary" size="lg" color="primary">
                                    {loader ? "Creating Account..." : "Create Account"}
                                </CButton>
                                <CRow className="mt-4" >
                                    <CCol lg="12" xl="6" className="text-center" >
                                        <FacebookLogin
                                            appId={FB_ID}
                                            autoload={false}
                                            callback={(resp) => responseFacebook(resp)}
                                            onFailure={(err) => console.log(err)}
                                            render={renderProps => (
                                                <CButton style={{
                                                    background: "#EBF1FC",
                                                    color: "#335DA7",
                                                    fontWeight: "bold"
                                                }} onClick={renderProps.onClick}
                                                            className="px-4 btn-pill mb-2 btn200"
                                                            variant="outline" disabled={fbLoader}>
                                                    <img src={fbIcon} className="mr-2 middle"/>
                                                    <span
                                                        className="middle">{fbLoader ? "Processing..." : "Facebook"}</span></CButton>
                                            )}
                                        />
                                    </CCol>
                                    <CCol lg="12" xl="6" className="text-center" >
                                        <GoogleLogin
                                            clientId={GOOGLE_ID}
                                            render={renderProps => (
                                                <CButton style={{
                                                    background: "#FBEEF0",
                                                    color: "#ED0028",
                                                    fontWeight: "bold"
                                                }} onClick={renderProps.onClick}
                                                            className="px-4 btn-pill mb-2 btn200"
                                                            variant="outline" disabled={googleLoader}>
                                                    <img src={googleIcon} className="mr-2 middle"/>
                                                    <span
                                                        className="middle">{googleLoader ? "Processing..." : "Google"}</span>
                                                </CButton>
                                            )}
                                            onSuccess={(resp) => responseGoogle(resp)}
                                            onFailure={(err) => console.log(err)}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </CCol>
                                </CRow>
                                <div className="text-center mt-3" >
                                    <CButton className="mb-3 lead p" color="link" onClick={() => {
                                        props.history.push("/login")
                                    }}>Have an account? <b className="yellowText bold">Log-in</b></CButton>
                                </div>
                            </CCol>
                        </CRow>
                    </CForm>
                </CCardBody>
                {verification && <VerifyAccountModal msg={verifyMessage} client={true} modal={verification} toggle={setVerification} />}
            </CCard>
        </>
    );
};

const mapDispatchToProps = {
    register,
    socialLogin
}
export default connect(null, mapDispatchToProps)(withRouter(ClientRegister));
