import localforage from 'localforage';

const INTENDED_URL = 'intended_url';
const DEFAULT_INTENDED_URL = '/';

async function setLocalForageToken(token) {
  if (window._.isEmpty(token)) {
    await localforage.removeItem('authtoken', token);
  }
  await localforage.setItem('authtoken', token);
}

const setHttpToken = (token) => {
  if (window._.isEmpty(token)) {
    window.axios.defaults.headers.common['Authorization'] = null;
  }
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const checkTokenExists = () => {
  return localforage.getItem('authtoken').then((token) => {
    if (window._.isEmpty(token)) {
      return Promise.reject(new Error('invalid token'));
    }
    return Promise.resolve(token);
  })
};

export const setToken = token => {
  setLocalForageToken(token);
  setHttpToken(token);
};

export const setIntendedUrl = url => {
  localforage.setItem(INTENDED_URL, url);
};

export const setUserType = (type) => {
   return localforage.setItem("userType", type);
};

export const getUserType = () => {
  return localforage.getItem("userType");
};

export const deleteIntendedUrl = url => {
  localforage.removeItem(INTENDED_URL, url);
};

export const getIntendedUrl = () => {
  return localforage.getItem(INTENDED_URL).then((url) => {
    if (window._.isEmpty(url)) {
      url = DEFAULT_INTENDED_URL;
    }
    if(url == "/home"){
      url = "/"
    }
    return Promise.resolve(url);
  });
};
