import React, {useState, useEffect, useRef} from "react";
import {
    CForm,
    CAlert,
    CRow,
    CCol,
    CInvalidFeedback,
    CInput,
    CSelect,
    CInputCheckbox,
    CInputRadio,
} from "@coreui/react";
import {useForm} from 'react-hook-form';
import { Country, State, City }  from 'country-state-city';
import StepComp from "./StepComp";
import { getCountryNameName } from "../../../../../helpers/CommonHelper";

const Company = (props) => {
    const {data} = props
    const {register, handleSubmit, errors, setError, watch} = useForm()
    const countryListArray = Country.getAllCountries()
    const [selectedCountry, setSelectedCountry] = useState(props.reqData.details.company_country)
    const [bSizes, setBSizes] = useState(props.reqData.details.business_size)
    const [error, setDefaultError] = useState(false)
    const watchAllFields = watch()

    const onSubmit = (data) => {
        data["business_size"] = bSizes
        props.saveStepData("details", data)
        props.incrementStep()
    };

    const handleCheckbox = (checked, childId) => {
        setBSizes(childId)
    }

    return (
        <div>
        <p className="heading"  >Company - Tell us a about your business</p>
        <p className="subHeading bold mt-3 mb-4" >Where is your business located ?</p>
        <CForm >
            {error && <CAlert color="danger">
                {error}
            </CAlert>}
            <CRow>
                <CCol>
                    <div className="mb-4">
                        <p className="mb-2 bold" >Country</p>
                        <CSelect  custom size="md"
                            onChange={(val) => setSelectedCountry(val.target.value)}
                            name="company_country" style={{maxWidth: "100%"}}
                            className="cutomSelect d-block"
                            invalid={errors.company_country && true}
                            innerRef={register({required: true})}
                            defaultValue={props.reqData.details.company_country}
                            >
                            <option value="">Select Country</option>
                            {countryListArray.map((country) => (
                                <option value={country.isoCode}>{getCountryNameName(country.name)}</option>
                            ))}
                        </CSelect>
                        {errors.company_country &&
                        <CInvalidFeedback>Country is required</CInvalidFeedback>}
                    </div>
                </CCol>
                <CCol>
                    <div className="mb-4">
                        <p className="mb-2 bold" >State/Department/Province</p>
                        <CSelect custom size="md"
                            name="company_state" style={{maxWidth: "100%"}}
                            className="cutomSelect d-block"
                            invalid={errors.company_state && true}
                            innerRef={register({required: State.getStatesOfCountry(selectedCountry).length > 0})}
                            defaultValue={props.reqData.details.company_state}
                            disabled={State.getStatesOfCountry(selectedCountry).length == 0}
                            >
                            <option value="">Select State</option>
                            {State.getStatesOfCountry(selectedCountry).map((state) => (
                                <option value={state.isoCode}>{state.name}</option>
                            ))}
                        </CSelect>
                        {errors.company_state &&
                        <CInvalidFeedback>State is required</CInvalidFeedback>}
                    </div>
                </CCol>
            </CRow>
            <div className="mt-4" >
                <p className="subHeading bold" >Business size</p>
                <CRow>
                    <CCol md="2" >
                        <div className="d-flex align-items-center ml-4 mb-2" >
                            <CInputRadio name="b_size" className="mr-2 mt-0" 
                            onClick={(e) => handleCheckbox(e.target.checked, "individual")}
                            defaultChecked={bSizes.includes("individual") ? true : false} 
                            />
                            <p className="mb-0 lead" >Individual</p>
                        </div>
                    </CCol>
                    <CCol md="2">
                        <div className="d-flex align-items-center ml-4 mb-2" >
                            <CInputRadio name="b_size" className="mr-2 mt-0"
                             onClick={(e) => handleCheckbox(e.target.checked, "1-10")}
                             defaultChecked={bSizes.includes("1-10") ? true : false}
                            />
                            <p className="mb-0 lead" >1-10</p>
                        </div>
                    </CCol>
                    <CCol md="2">
                        <div className="d-flex align-items-center ml-4 mb-2" >
                            <CInputRadio name="b_size" className="mr-2 mt-0"
                            onClick={(e) => handleCheckbox(e.target.checked, "11-100")}
                            defaultChecked={bSizes.includes("11-100") ? true : false}
                            />
                            <p className="mb-0 lead" >11-100</p>
                        </div>
                    </CCol>
                    <CCol md="2">
                        <div className="d-flex align-items-center ml-4 mb-2" >
                            <CInputRadio name="b_size" className="mr-2 mt-0"
                            onClick={(e) => handleCheckbox(e.target.checked, "501-1000")}
                            defaultChecked={bSizes.includes("501-1000") ? true : false}
                            />
                            <p className="mb-0 lead" >501-1000</p>
                        </div>
                    </CCol>
                    <CCol md="2">
                        <div className="d-flex align-items-center ml-4 mb-2" >
                            <CInputRadio name="b_size" className="mr-2 mt-0"
                             onClick={(e) => handleCheckbox(e.target.checked, "5000+")}
                             defaultChecked={bSizes.includes("5000+") ? true : false}
                            />
                            <p className="mb-0 lead" >5000+</p>
                        </div>
                    </CCol>

                </CRow>
            </div>

            <div className="mt-4" >
                <p className="subHeading bold" >Company  website</p>
                <CRow>
                    <CCol md="6" >
                        <div className="mb-4">
                            <CInput defaultValue={props.reqData.details.company_website} className="customInput" invalid={errors.company_website && true} name="company_website" type="text"
                            placeholder="domain.com" innerRef={register({})}/>
                            {errors.company_website &&
                            <CInvalidFeedback>{errors.company_website.message}</CInvalidFeedback>}
                        </div>
                    </CCol>
                </CRow>
            </div>

        </CForm>
        <StepComp
            decrementStep={() => {props.decrementStep("details", {
                ...watchAllFields,
                ...{bSizes: bSizes}
            })}} onSubmit={handleSubmit(onSubmit)}  />
    </div>
    )
}

export default Company