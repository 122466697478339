export const sygnet = ['460 460', `
  <title>builit logo</title>
  <g>
  <path
    d="M89.344 47.898c.143 12.486.375 28.957.515 36.602l.255 13.9H32.4v203.2h56.8v73.2l86.1-.002c101.416-.002 100.928.016 115.369-4.156 66.432-19.187 97.152-94.829 62.723-154.442-3.114-5.393-5.349-8.589-9.892-14.149-1.849-2.263-1.971-1.671 1.122-5.451C390.49 140.541 364.358 53.166 295 30.684c-17.124-5.551-13.903-5.403-118.958-5.449l-86.958-.039.26 22.702M270.8 99.925c31.356 9.645 30.796 53.32-.8 62.316-2.451.698-5.258.747-48.9.861l-46.3.12v73.556l46.3.12c51.955.136 47.911-.097 54.776 3.152 24.101 11.404 24.307 45.97.345 57.73-7.182 3.525 1.881 3.189-89.521 3.317l-81.1.114V98.789l81.1.107 81.1.106 3 .923"
    fill="#e4942c"
    fillRule="evenodd"
  />
  </g>
`]
