import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import App from './router';
import { Provider } from 'react-redux';
import ErrorBoundary from "./pages/ErrorBoundary";
import { icons } from './assets/icons'
import './scss/style.scss';
import './scss/custom.css';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { hotjar } from 'react-hotjar';
import {Helmet} from "react-helmet";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

if(process.env.REACT_APP_ENV == "production"){
    // hotjar.initialize(2606474, 6);
    Sentry.init({
        dsn: "https://ad14a589ef694d41badd8d84a267f3b7@o500394.ingest.sentry.io/5580212",
        autoSessionTracking: true,
        integrations: [
          new Integrations.BrowserTracing(),
        ],
      
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
}

let token = document.head.querySelector('meta[name="csrf-token"]');

React.icons = icons

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/21731649.js"></script>
            </Helmet>
            <GTMProvider state={{"id": "GTM-N5GGNSQ"}}>
                <App />
            </GTMProvider>
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);