import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link, useHistory} from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CRow,
} from "@coreui/react";
import { STORE } from "../../../../../helpers/CommonHelper";
import { getExpertise, saveClientQuery } from "../../../../../actions/ExpertActions";
import SearchExpertise from "./SearchExpertise";
import SearchSkills from "./SearchSkills";
import Company from "./CompanyInfo";
import RoleDetails from "./RoleDetails";
import Review from "./Review";
import Loader from "../../../../../components/Loader";
import { useSelector } from "react-redux";
import CIcon from '@coreui/icons-react';
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const Success = (props) => {
    const history = useHistory()
    return (
        <div>
            <p className="heading bold" >Success!</p>
            <p className="subHeading" >We have received your query and we will get back to you in 24 hours.</p>
            <CRow >
                <CCol md="6" className="p-3 ml-4" style={{border: "1px solid lightgrey", borderRadius: "5px", background: "rgb(248, 248, 248)"}} >
                    <p className="lead bold mr-5" >Summary</p>
                    <div className="d-flex align-items-center"  >
                    <div >
                        <p className="lead mr-5" >Talent Type</p>
                        <p className="lead mr-5">Skills</p>
                        <p className="lead mr-5">Location</p>
                        <p className="lead mr-5">Rate</p>
                        <p className="lead mr-5">Hours</p>
                    </div>
                    <div>
                        <p className="lead mr-5">{(props.reqData.selectedExpertise.map((v) => {
                            return <span>{v.childTitle} {" "}</span>
                        }))}</p>
                        <p className="lead mr-5">{(props.reqData.selectedSkills.map((v) => {
                            return <span>{v.childTitle} {" "}</span>
                        }))}</p>
                        <p className="lead mr-5">{props.reqData.details.expert_location}</p>
                        <p className="lead mr-5">${props.reqData.details.expert_max_budget} / Hour</p>
                        <p className="lead mr-5">{props.reqData.details.expert_hours_week} hours per week</p>
                    </div>
                </div>
                </CCol>
            </CRow>
            <CRow className="mt-5">
                <CCol className="d-flex align-items-center justify-content-end">
                    <CButton
                        size="lg"
                        onClick={() => history.push("/client/onboard")}
                        className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                        {/* <CIcon size="xl"name="cil-arrow-left"/> */}
                        <p className="mb-0 lead" >Start A New Request</p>
                    </CButton>
                    <CButton
                        size="lg"
                        onClick={() => history.push("/dashboard")}
                        className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                        <p className="mb-0 lead" >Close</p>
                        {/* <CIcon size="xl"name="cil-arrow-right"/> */}
                    </CButton>
                </CCol>
            </CRow>
        </div>
    )
}

const FindExperts = props => {
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [step, setStep] = useState(1)
    const showCompanyStep = useSelector(state => state.client.showCompany)
    const sendDataToGTM = useGTMDispatch()

    const [reqData, setReqData] = useState({
        details: {
            company_country: null,
            company_website: null,
            business_size: [],
            company_state: null
        },
        selectedSkills: [],
        selectedExpertise: []
    })

    useEffect(() => {
        STORE.dispatch(getExpertise()).then((resp) => {
            if(resp.hasRequests){
                STORE.dispatch({
                    type: "SET_SHOW_COMPANY",
                    payload: false
                })
            }
            setData(resp)
            // resp["selectedExpertise"] = []
            // setReqData(resp)
            setLoading(false)
        })
    }, [])

    const saveStepData = (key, obj) => {
        var reqDataCopy = {...reqData}
        if(key == "details"){
            reqDataCopy[key] = {...reqDataCopy[key], ...obj}
        }if(key == "expertData"){
            reqDataCopy["selectedExpertise"] = obj.selectedExpertise
            reqDataCopy["requestTitle"] = obj.requestTitle
        }else{
            reqDataCopy[key] = obj
        }
        setReqData(reqDataCopy)
    }

    const incrementStep = () => {
        if(step == 2 && !showCompanyStep){
            setStep(step+2)
        }else{
            setStep(step+1)
        }
    }

    const decrementStep = (key = null, obj = null) => {
        if(key && obj){
            saveStepData(key, obj)
        }
        if(step == 4 && !showCompanyStep){
            setStep(step-2)
        }else{
            setStep(step-1)
        }
    }

    const submitData = () => {
        var reqDataC = {...reqData}
        reqDataC["request_type"] = "detailed"
        STORE.dispatch(saveClientQuery(reqDataC)).then(() => {
            sendDataToGTM({ event: 'detailedQuote', value: true })
            incrementStep()
        })
    }

    const renderScreen = () => {
        if(step == 1){
            return <SearchExpertise incrementStep={incrementStep} decrementStep={decrementStep} reqData={reqData}  data={data} saveStepData={saveStepData} />
        }else if(step == 2){
            return <SearchSkills incrementStep={incrementStep} decrementStep={decrementStep} reqData={reqData} data={data} saveStepData={saveStepData} />
        }else if(step == 3){
            return <Company incrementStep={incrementStep} decrementStep={decrementStep} reqData={reqData} data={data} saveStepData={saveStepData} />
        }else if (step == 4){
            return <RoleDetails reqData={reqData} data={data} incrementStep={incrementStep} decrementStep={decrementStep} saveStepData={saveStepData} />
        }else if (step == 5){
            return <Review submitData={submitData} decrementStep={decrementStep} reqData={reqData} expertise={data.expertise} skills={data.skills} />
        }else if (step == 6){
            return <Success reqData={reqData} expertise={data.expertise} skills={data.skills} />
        }
    }

    return (
        <CCard className="boxShadow mt-5">
            <CCardBody>
            { loading ? (<Loader/> ) : renderScreen() }
        </CCardBody>
        </CCard>    
    );
};

export default withRouter(FindExperts);
