import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    CRow,
    CCol,
    CButton,
    CCard,
    CCardBody,
    CInputCheckbox,
    CInput
} from "@coreui/react";
import 'react-phone-input-2/lib/style.css'

const ExpertiseFirst = props => {

    const {data, total, setData} = props
    const [others, setOthers] = useState({})

    const arrayChunks = (parentName, resp) => {
        var other = null
        var exludedData = []
        resp.map((cat) => {
            if(cat.title != "Other"){
                exludedData.push(cat)
            }else{
                other = cat
            }
        })
        var chunks = [] 
        var i,j, temporary, chunk = parentName == "Industries" ? 16 : 4;
        for (i = 0,j = exludedData.length; i < j; i += chunk) {
            temporary = exludedData.slice(i, i + chunk)
            chunks.push(temporary)
        }
        if(other){
            chunks[chunks.length-1].push(other)
        }
        return chunks
    }

    useEffect(() => {
    }, []);

    const handleCheckbox = (checked, childId, title) => {
        var catsCopy = {...props.selectedCats}
        if(checked){
            catsCopy.ids.push(childId)
        }else{
            catsCopy.ids.splice(catsCopy.ids.indexOf(childId),1)
            catsCopy.titles.splice(catsCopy.ids.indexOf(childId),1)
        }
        props.setSelectedCats(catsCopy)
        props.handleCatClick(checked, childId, title)
    }

    const handleOther = (e, childId, parentId) => {
        var catsCopy = {...props.selectedCats}
        catsCopy.titles[catsCopy.ids.indexOf(childId)] = e.target.value
        props.setSelectedCats(catsCopy)
        props.handleCatClick(true, childId, e.target.value)
    }

    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-2 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >2</p>
                    </div>
                    <p className="heading whiteText">Provide your expertise 1 of 3</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                    <h3 className="bold mb-3" >Functional Expertise</h3>
                    <CRow>
                        {data && data.map((parent) => {
                            if(["Product Stage", "Product Domain", "Industries", "Product Roadmap", "Product Stages", "Product Types"].includes(parent.title)) return null
                            return <CCol md="6" >
                                <p className="subHeading bold yellow" >{parent.title}</p>
                                <CRow>
                                    {arrayChunks(parent.title, parent.childs).map((childChunk) => (
                                        <CCol className="mb-4" md="6" >
                                            {childChunk.map((child, index) => (
                                                <div>
                                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                                        <CInputCheckbox defaultChecked={props.selectedCats.ids.includes(child.uuid) ? true : false} onClick={(e) => handleCheckbox(e.target.checked, child.uuid, child.title)} className="mr-2"  />
                                                        <p className="mb-0" >{child.title}</p>
                                                    </div>
                                                    {(child.title == "Other" && props.selectedCats.ids.includes(child.uuid)) && <CInput className="mr-4 customInput bold otherInput" defaultValue={props.selectedCats.titles[props.selectedCats.ids.indexOf(child.uuid)]} name="other" onChange={(e) => handleOther(e, child.uuid, parent.uuid)} ></CInput>}
                                                </div>
                                            ))}
                                        </CCol>
                                    ))}
                                </CRow>
                            </CCol>
                        })}
                    </CRow>
                    <CRow className="mt-5">
                            <CCol className="d-flex justify-content-between align-items-center">
                                <CButton color="secondary"  className="btn100 btn-pill bold" onClick={() => props.updateStep(props.step-1)}>Back</CButton>
                                <CButton color="primary"  className="btn100 btn-pill bold" onClick={() => props.updateStep(props.step+1)}>Next Step</CButton>
                            </CCol>
                        </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withRouter(ExpertiseFirst));
