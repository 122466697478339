import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    CRow,
    CCol,
    CButton,
    CCard,
    CCardBody,
    CInputCheckbox,
    CInput
} from "@coreui/react";
import 'react-phone-input-2/lib/style.css'

const ExpertSkills = props => {

    const {data, total} = props
    const [others, setOthers] = useState({})

    const arrayChunks = (parentName, resp) => {
        var other = null
        var exludedData = []
        resp.map((cat) => {
            if(cat.title != "Other"){
                exludedData.push(cat)
            }else{
                other = cat
            }
        })
        var chunks = [] 
        var i,j, temporary, chunk = exludedData.length >= 5 ? 5 : 4;
        for (i = 0,j = exludedData.length; i < j; i += chunk) {
            temporary = exludedData.slice(i, i + chunk)
            chunks.push(temporary)
        }
        if(other){
            chunks[chunks.length-1].push(other)
        }
        return chunks
    }

    useEffect(() => {
    }, []);

    const handleCheckbox = (checked, childId, title) => {
        var catsCopy = {...props.selectedSkills}
        if(checked){
            catsCopy.ids.push(childId)
        }else{
            catsCopy.ids.splice(catsCopy.ids.indexOf(childId),1)
            catsCopy.titles.splice(catsCopy.ids.indexOf(childId),1)
        }
        props.setSelectedSkills(catsCopy)
        props.handleCatClick(checked, childId, title)
    }

    const handleOther = (e, childId) => {
        var catsCopy = {...props.selectedSkills}
        catsCopy.titles[catsCopy.ids.indexOf(childId)] = e.target.value
        props.setSelectedSkills(catsCopy)
        props.handleCatClick(true, childId, e.target.value)
    }

    const orderTheSkills = () => {
        var ordered = []
        var order = [
            "Professional Experience",
            "Education",
            "Certifications",
            "Purchasing",
            "Professional Skills",
            "Purchasing & Procurement Skills",
            "Operations & Supply Chain Skills",
            "Distribution & Warehouse Skills",
            "Logistics & Freight Skills",
            "Data Analytics & Reporting Skills",
            "Engineering & Quality Skills",
            "Materials Experience",
            "Regulatory Experience",
            "Software",
            "eCommerce Platforms",
            "Websites & Platforms"
        ]
        order.map((item) => {
            data && data.map((parent) => {
                if(parent.title.toLowerCase() == item.toLowerCase()){
                    ordered.push(parent)
                }
            })
        })
        return ordered
    }


    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >2</p>
                    </div>
                    <p className="heading whiteText">Skills - 2 of 3</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                    <h2 className="bold mb-3" >Skills</h2>
                    <CRow>
                        {orderTheSkills().map((parent) => (
                            <CCol md="6" > 
                                <p className="subHeading bold yellow capitalize" >{parent.title}</p>
                                <CRow className="my-3" >
                                    {arrayChunks(parent.title, parent.childs).map((childChunk) => (
                                        <CCol md="6"  >
                                            {childChunk.map((child, index) => (
                                                <div>
                                                    <div className="d-flex align-items-center ml-4 mb-2" >
                                                        <CInputCheckbox defaultChecked={props.selectedSkills.ids.includes(child.uuid) ? true : false} onClick={(e) => handleCheckbox(e.target.checked, child.uuid, child.title)} className="mr-2"  />
                                                        <p className="mb-0" >{child.title}</p>
                                                    </div>
                                                    {(child.title == "Other" && props.selectedSkills.ids.includes(child.uuid)) && <CInput className="mr-4 customInput bold otherInput" defaultValue={props.selectedSkills.titles[props.selectedSkills.ids.indexOf(child.uuid)]} name="other" onChange={(e) => handleOther(e, child.uuid)} ></CInput>}
                                                </div>
                                            ))}
                                        </CCol>
                                    ))}
                                </CRow>
                            </CCol>
                        ))}
                    </CRow>
                    <CRow className="mt-5">
                            <CCol className="d-flex justify-content-between align-items-center">
                                <CButton color="secondary" className="btn100 btn-pill bold" onClick={() => props.updateStep(props.step-1)}>Back</CButton>
                                <CButton color="primary" className="btn100 btn-pill bold" onClick={() => props.updateStep(props.step+1)}>Next Step</CButton>
                            </CCol>
                        </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withRouter(ExpertSkills));
