window._ = require('lodash');
var swal = require('sweetalert');
const { PUSHER_APP_KEY, PUSHER_CLUSTER, BASE_URL } = require('./config/config');
const { checkTokenExists, getUserType } = require('./helpers/auth');
const { TOAST } = require('./helpers/CommonHelper');
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

// try {
//     window.Popper = require('popper.js').default;
//     window.$ = window.jQuery = require('jquery');

//     require('bootstrap');
// } catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = BASE_URL
window.axios.interceptors.response.use(function(response){
    return response;
}, function (error) {
    if (401 === error.response.status) {
        if(window.location.pathname != "/login"){
            swal({text:"Session expired, please log in again.", button: "Re-Login", title: "Session Expired"})
            .then(async (value) => {
                const userType = await getUserType()
                if(userType == "expert"){
                    window.location.href = "/expert/login"
                }else{
                    window.location.href = "/login"
                }
            })
        }
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

checkTokenExists().then(
    val => {
        window.pusher = new Pusher(PUSHER_APP_KEY, {
            cluster: PUSHER_CLUSTER,
            authEndpoint: '/api/authorize',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + val
                },
            }
        });
    }

).catch((err) => {
    console.log("Token not found")
});
