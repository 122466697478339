import { setToken } from "../helpers/auth";
import { setAuthenticated, setUserData } from "./auth";

export const searchUser = (query, pm = null) => dispatch => {
    var url = ""
    if(pm){
        url = '/api/searchUser?query='+query+"&pm=true"
    }else{
        url = '/api/searchUser?query='+query
    }
    return  window.axios.get(url).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const profile = () => dispatch => {
    return  window.axios.get('/api/profile').then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const disableAccount = () => dispatch => {
    return  window.axios.post('/api/disableAccount').then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const resetPassword = (data) => dispatch => {
    return  window.axios.post('/api/resetPassword', data).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const verifyAccount = (req) => dispatch => {
    return  window.axios.post('/api/verifyAccount', req).then(({data}) => {
        setToken(data.meta.token);
        dispatch(setAuthenticated(true));
        dispatch(setUserData(data.data));
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const doubleOptIn = (data) => dispatch => {
    return  window.axios.post('/api/doubleOptIn', data).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const deleteAccount = () => dispatch => {
    return  window.axios.post('/api/deleteAccount').then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}


export const subscription = () => dispatch => {
    return  window.axios.get('/api/subscription').then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updateSubscription = (data) => dispatch => {
    return  window.axios.post('/api/updateSubscription', data).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const inviteFriends = (data) => dispatch => {
    return  window.axios.post('/api/inviteFriends', data).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updateExpertStatus = (data) => dispatch => {
    return  window.axios.post('/api/updateExpertStatus', data).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updateProfile = (data) => dispatch => {

    let formData = new FormData();
    formData.append('first_name', data.first_name);
    if(data.media){
        formData.append('media', data.media);
    }
    if(data.last_name){
        formData.append('last_name', data.last_name);
    }

    if(data.last_name){
        formData.append('last_name', data.last_name);
    }

    if(data.mobile){
        formData.append('mobile', data.mobile);
    }

    if(data.address1){
        formData.append('address1', data.address1);
    }

    if(data.address2){
        formData.append('address2', data.address2);
    }

    if(data.country_code){
        formData.append('country_code', data.country_code);
    }


    if(data.postal_code){
        formData.append('postal_code', data.postal_code);
    }


    if(data.city){
        formData.append('city', data.city);
    }


    if(data.state){
        formData.append('state', data.state);
    }

    if(data.password){
        formData.append('password', data.password);
    }


    if(data.new_password){
        formData.append('new_password', data.new_password);
    }

    if(data.company){
        formData.append('company', data.company);
    }
    if(data.company_website){
        formData.append('company_website', data.company_website);
    }

    return  window.axios.post('/api/updateProfile', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then((data) => {
        return Promise.resolve(data.data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}