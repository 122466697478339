import React, {useState, useEffect} from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CInvalidFeedback,
    CAlert,
    CInputCheckbox,
    CLabel,
    CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {useForm} from 'react-hook-form';
import {getIntendedUrl, setIntendedUrl, setUserType} from '../../helpers/auth';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {login, socialLogin} from "../../actions/auth"
import landingBg from "../../assets/images/landingBg.png"
import {Divider} from '../../components/Divider';
import queryString from "query-string";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import {FB_ID, GOOGLE_ID} from '../../helpers/CommonHelper';
import googleIcon from "../../assets/images/icons/google_login_icon.png"
import fbIcon from "../../assets/images/icons/fb_login_icon.png"
import ForgotPasswordModal from '../../modals/ForgotPasswordModal';
import VerifyAccountModal from '../../modals/VerifyAccountModal';

const Login = (props) => {

    let params = queryString.parse(props.location.search);

    useEffect(() => {
        if (params.msg != undefined) {
            setError("Please login/register first in order to access this module.")
        }
    }, []);

    const {register, handleSubmit, errors} = useForm();
    const [loader, setLoader] = useState(false)
    const [forgotModal, setForgotModal] = useState(false)
    const [fbLoader, setFbLoader] = useState(false)
    const [verification, setVerification] = useState(false)
    const [googleLoader, setGoogleLoader] = useState(false)
    const [error, setError] = useState(false)

    const onSubmit = (data) => {
        setLoader(true)
        props.login(data).then(async (resp) => {
            setLoader(false)
            setUserType("client")
            getIntendedUrl().then((url) => {
                setIntendedUrl("/")
                window.location.href = "/dashboard"
            })
            // if(resp.data.type == "client"){
            //     window.location.href = "/client/onboard"
            // }else if(resp.data.type == "expert"){
            //     window.location.href = "/expertSteps"
            // }
        }).catch((e) => {
            setLoader(false)
            if(e?.redirect){
                setVerification(true)
            }else{
                setError(e)
            }
        })
    };

    const responseFacebook = (resp) => {
        if (resp.accessToken != undefined) {
            setFbLoader(true)
            props.socialLogin({
                accessToken: resp.accessToken,
                type: "facebook",
                userType : "client"
            }).then(async (response) => {
                // CHECK IF USER INVITED FOR A PROJECT
                if (params.pid) {
                    await props.joinProject({pid: params.pid})
                }
                setUserType("client")
                getIntendedUrl().then(async (url) => {
                    // reset intended URL in local forag
                    setIntendedUrl("/dashboard")
                    window.location.href = url
                }).catch((e) => {
                    setFbLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    const responseGoogle = (resp) => {
        if (resp.accessToken != undefined) {
            setGoogleLoader(true)
            props.socialLogin({
                accessToken: resp.accessToken,
                profileObj: resp.profileObj,
                type: "google",
                userType : "client"
            }).then(async (response) => {
                // CHECK IF USER INVITED FOR A PROJECT
                if (params.pid) {
                    await props.joinProject({pid: params.pid})
                }
                setUserType("client")
                getIntendedUrl().then(async (url) => {
                    // reset intended URL in local forag
                    setIntendedUrl("/dashboard")
                    window.location.href = url
                }).catch((e) => {
                    setGoogleLoader(false)
                    setError(e)
                })
            })
        } else {
            alert("Something went wrong!")
        }
    }

    const handleRegisterLink = () => {
        if (params.pid) {
            props.history.push("/register?pid=" + params.pid)
        } else {
            props.history.push("/client/register")
        }
    }

    return (
        <div
            className={props.modal != undefined ? "flex-row align-items-center" : "flex-row align-items-center mb-5 mt-5"}>
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="12" lg="6" className="leftLoginBox d-lg-flex align-items-center flex-column d-none"
                          style={{padding: "50px"}}>
                        <div>
                            <p className="mb-3 heading">Not a member yet?</p>
                            <p className="lead mb-5 subHeading">
                            Sign up as a <span className="yellowText bold" >consultant</span> or as an <span className="yellowText bold">entrepreneur or company with a project</span> 
                            </p>
                        </div>
                        <div>
                            <img className="img-fluid" src={landingBg}/>
                        </div>
                        {/* <p className="lead mt-5 mb-0" >Didn’t receive the email confirmation? <span className="yellowText bold">Resend</span></p> */}
                    </CCol>
                    <CCol md="12" lg="6" className="rightLoginBox">
                        {/* <div className="text-center" style={{marginBottom: 30}} >
              <img src={Logo} width="300" />
              </div> */}
                        {props.modal && <CIcon name="cil-x-circle" size="xl" className="closeIcon pointer"
                                               onClick={() => props.setModal()}/>}
                        <CCardGroup>
                            <CCard className="p-4 loginCard">
                                <CCardBody className="p-0">
                                    <CForm onSubmit={handleSubmit(onSubmit)}>
                                        {error && <CAlert color="danger">
                                            {error}
                                        </CAlert>}
                                        <p className="heading mb-5">Login</p>
                                        <CInputGroup className="mb-4 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.name && true} name="name" type="text"
                                                    placeholder="Username/Email" innerRef={register({required: true})}/>
                                            {errors.name &&
                                            <CInvalidFeedback>Username/Email is required</CInvalidFeedback>}
                                        </CInputGroup>
                                        <CInputGroup className="mb-3 text-left">
                                            <CInputGroupPrepend>
                                                <CInputGroupText className="customInputIcon">
                                                    <CIcon name="cil-lock-locked"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput className="customInputLogin" invalid={errors.password && true} name="password" type="password"
                                                    placeholder="Password" innerRef={register({required: true})}/>
                                            {errors.password &&
                                            <CInvalidFeedback>Password is required.</CInvalidFeedback>}
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="12">
                                                <div>
                                                    <CButton className="mb-5 lead p bold"
                                                             onClick={() => setForgotModal(true)} color="link">Forgot
                                                        Password?</CButton>
                                                </div>
                                                <div>
                                                    <CButton className="px-4 btn-pill mb-2 btn200" color="primary"
                                                            type="submit" disabled={loader} >{ loader ? "Login In..." :  "Login" }</CButton>
                                                </div>
                                                <div>
                                                    <CButton className="mb-3 lead p" color="link" onClick={() => {
                                                        props.modal != undefined ? props.setRegisterModal(props.setModalType(2)) : handleRegisterLink()
                                                    }}>Don't have an account? <b className="yellowText bold">Register
                                                        First</b></CButton>
                                                </div>
                                                <Divider className="mb-5"/>

                                                <CRow>
                                                    <CCol lg="12" xl="6">
                                                        <FacebookLogin
                                                            appId={FB_ID}
                                                            callback={(resp) => responseFacebook(resp)}
                                                            onFailure={(err) => console.log(err)}
                                                            render={renderProps => (
                                                                <CButton style={{
                                                                    background: "#EBF1FC",
                                                                    color: "#335DA7",
                                                                    fontWeight: "bold"
                                                                }} onClick={renderProps.onClick}
                                                                         className="px-4 btn-pill mb-2 btn200"
                                                                         variant="outline" disabled={fbLoader} >
                                                                    <img src={fbIcon} className="mr-2 middle"/> <span
                                                                    className="middle">{fbLoader ? "Working..." : "Facebook"}</span></CButton>
                                                            )}
                                                        />
                                                    </CCol>

                                                    <CCol lg="12" xl="6">
                                                        <GoogleLogin
                                                            clientId={GOOGLE_ID}
                                                            render={renderProps => (
                                                                <CButton style={{
                                                                    background: "#FBEEF0",
                                                                    color: "#ED0028",
                                                                    fontWeight: "bold"
                                                                }} onClick={renderProps.onClick}
                                                                         className="px-4 btn-pill mb-2 btn200"
                                                                         variant="outline" disabled={googleLoader}>
                                                                    <img src={googleIcon} className="mr-2 middle"/>
                                                                    <span
                                                                        className="middle">{googleLoader ? "Working..." : "Google"}</span>
                                                                </CButton>
                                                            )}
                                                            onSuccess={(resp) => responseGoogle(resp)}
                                                            onFailure={(err) => console.log(err)}
                                                            cookiePolicy={'single_host_origin'}
                                                        />

                                                    </CCol>

                                                </CRow>

                                                <p className="lead mt-4 p">By signing in to Supplino, you agree to
                                                    our <CLink target="_blank" download
                                                               to={{pathname: "https://buildit.studio/launch/terms-and-conditions/"}}><u
                                                        className="yellowText bold">Terms</u></CLink> and <CLink
                                                        target="_blank" download
                                                        to={{pathname: "https://buildit.studio/launch/privacy-policy/"}}><u
                                                        className="yellowText bold">Privacy Policy</u></CLink>.</p>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
            {verification && <VerifyAccountModal modal={verification} toggle={setVerification} />}
            {forgotModal && <ForgotPasswordModal modal={forgotModal} toggle={setForgotModal}/>}
        </div>
    )
}

const mapDispatchToProps = {
    login,
    socialLogin,
    
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
