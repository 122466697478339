import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    CModal,
    CModalBody,
    CContainer,
    CForm,
    CAlert,
    CFormGroup,
    CLabel,
    CInput,
    CRow,
    CCol,
    CSelect,
    CTextarea,
    CButton,
    CInvalidFeedback,
    CCard,
    CCardBody
} from "@coreui/react";
import dummy from "../../assets/dummy.jpg"
import {useForm, Controller} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import countryList from 'react-select-country-list'
import Skeleton from "react-loading-skeleton";
import {updateProfile, profile, updateExpertStatus} from "../../actions/UserActions"
import { Country, State, City }  from 'country-state-city';
import store from "../../store";

const SetupProfile = props => {
    const countryListArray = Country.getAllCountries()
    const [error, setError] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [loader, setLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false)
    const [country, setCountry] = useState(props.data.country_code)
    const {register, handleSubmit, errors, getValues, control} = useForm();
    const inputFile = useRef(null)

    const [data, setData] = useState(props.data)

    useEffect(() => {
        // UPDATE STATUS
        store.dispatch(updateExpertStatus({status: "PROFILE"}))
    }, []);

    const onSubmit = (formData) => {
        if (imageUrl) {
            formData["media"] = imageUrl
        }
        setUpdateLoader(true)
        props.updateProfile(formData).then((resp) => {
            props.profile().then((resp) => {
                props.setProfile(resp)
                setUpdateLoader(false)
                props.updateStep(props.step+1)
            })
        }).catch((e) => {
            setUpdateLoader(false)
            setError(e)
        })
        // props.updateStep(props.step+1)
    };

    const _handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setImageUrl(file)
            var dataCopy = {...data}
            dataCopy["image"] = reader.result
            setData(dataCopy)
        }
        reader.readAsDataURL(file)
    }

    const onButtonClick = () => {
        inputFile.current.click();
    };

    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >1</p>
                    </div>
                    <p className="heading whiteText">Setup your profile</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                <CRow className="mb-5">
                    <CCol>
                        <CRow>
                            <CCol xs="12">
                                <h3 className="mb-4 bold">Profile</h3>
                            </CCol>
                            <CCol xl="4" lg="12" className="mb-5 mb-xl-0">
                                <div className="text-center">
                                    <div className="text-center">
                                        {loader ? (
                                            <Skeleton
                                                style={{width: "150px", height: "150px", borderRadius: "50%"}}
                                                duration={0.5}/>
                                        ) : (
                                            <img src={data.image ? data.image : dummy} className="img-circle" width="150" height={"150"}/>
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <input ref={inputFile} type="file" style={{display: "none"}}
                                                onChange={_handleImageChange}/>
                                        <CButton className="profileBtn btn-pill" type="file"
                                                    onClick={onButtonClick}>Change Photo</CButton>
                                    </div>
                                </div>
                            </CCol>
                            {loader ? (
                                <div className="adjustLoader">
                                    <span className="spinner-border" role="status"></span>
                                </div>
                            ) : (
                                <CCol>
                                    {!loader && <CForm autoComplete="off" >
                                        {error && <CAlert closeButton color="danger">
                                            {error}
                                        </CAlert>}
                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2" className="pr-1 bold">First
                                                        Name</CLabel>
                                                    <CInput data-lpignore="true" defaultValue={data.first_name} className="customInput"
                                                            placeholder="First Name" name="first_name"
                                                            invalid={errors.first_name && true}
                                                            innerRef={register({required: true})}/>
                                                    {errors.first_name && <CInvalidFeedback>First name is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2" className="pr-1 bold">Last
                                                        Name</CLabel>
                                                    <CInput defaultValue={data.last_name} className="customInput"
                                                            placeholder="Last Name" name="last_name"
                                                            invalid={errors.last_name && true}
                                                            innerRef={register({required: true})}/>
                                                    {errors.last_name && <CInvalidFeedback>Last name is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2" className="pr-1 bold">Email
                                                        Address</CLabel>
                                                    <CInput defaultValue={data.email} className="customInput"
                                                            placeholder="Email Address" name="email"
                                                            invalid={errors.email && true}
                                                            innerRef={register({required: true})}/>
                                                    {errors.email &&
                                                    <CInvalidFeedback>Email address is
                                                        required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2" className="pr-1 bold">Mobile
                                                        Number</CLabel>
                                                    <Controller
                                                        as={(
                                                            <PhoneInput
                                                                inputClass="customInput"
                                                                buttonClass="flagDropDown"
                                                                enableSearch={true}
                                                                inputProps={{
                                                                    autoComplete: "off"
                                                                }}
                                                            />
                                                        )}
                                                        name="mobile"
                                                        control={control}
                                                        defaultValue={data.mobile}
                                                    />

                                                    {/* <CInput className="customInput" placeholder="Mobile Number" name="number" invalid={errors.number && true} innerRef={register({ required: true })} /> */}
                                                    {/* {errors.number && <CInvalidFeedback>Mobile number is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>


                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">Company Name</CLabel>
                                                    <CInput defaultValue={data.company} className="customInput"
                                                            placeholder="Google" name="company"
                                                            innerRef={register()}/>
                                                    {/* {errors.city && <CInvalidFeedback>City is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">Company Website</CLabel>
                                                    <CInput defaultValue={data.company_website} className="customInput"
                                                            placeholder="https://example.com" name="company_website"
                                                            innerRef={register()}/>
                                                    {/* {errors.state && <CInvalidFeedback>State is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol xs="12">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">Address</CLabel>
                                                    <CInput defaultValue={data.address1} className="customInput"
                                                            placeholder="Street Address 1" name="address1"
                                                            innerRef={register()}/>
                                                    {/* {errors.address && <CInvalidFeedback>Address is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12">
                                                <CFormGroup className="pr-1">
                                                    <CInput defaultValue={data.address2} className="customInput"
                                                            placeholder="Street Addrees 2" name="address2"
                                                            innerRef={register()}/>
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">Country</CLabel>
                                                    <CSelect defaultValue={data.country_code} custom size="md"
                                                                name="country_code" style={{maxWidth: "100%"}}
                                                                className="cutomSelect d-block"
                                                                onChange={(e) => setCountry(e.target.value)}
                                                                innerRef={register()}>
                                                        <option value="">Select Country</option>
                                                        {countryListArray.map((country) => (
                                                            <option
                                                                value={country.isoCode}>{country.name}</option>
                                                        ))}
                                                    </CSelect>
                                                    {/* {errors.country && <CInvalidFeedback>Country is required</CInvalidFeedback>}                         */}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2" className="pr-1 bold">Zip/Postal
                                                        Code</CLabel>
                                                    <CInput defaultValue={data.postal_code} className="customInput"
                                                            placeholder="Zip/Postal Code" name="postal_code"
                                                            innerRef={register()}/>
                                                    {/* {errors.zip && <CInvalidFeedback>Zip/Postal Code is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">City</CLabel>
                                                    <CInput defaultValue={data.city} className="customInput"
                                                            placeholder="City" name="city"
                                                            innerRef={register()}/>
                                                    {/* {errors.city && <CInvalidFeedback>City is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel htmlFor="exampleInputName2"
                                                            className="pr-1 bold">State/Department/Region</CLabel>
                                                    <CSelect defaultValue={data.state} custom size="md"
                                                        name="state" style={{maxWidth: "100%"}}
                                                        className="cutomSelect d-block"
                                                        innerRef={register()}>
                                                        <option value="">Select State</option>
                                                        {State.getStatesOfCountry(country).map((country) => (
                                                            <option value={country.isoCode}>{country.name}</option>
                                                        ))}
                                                    </CSelect>
                                                    {/* {errors.state && <CInvalidFeedback>State is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow className="mt-4">
                                            <CCol className="text-right">
                                                <CButton color="primary" className="btn100 btn-pill" onClick={handleSubmit(onSubmit)} disabled={updateLoader}>{updateLoader ? "Saving" : "Next Step"}</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>}
                                </CCol>
                            )}
                        </CRow>
                    
                    </CCol>
                </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    updateProfile,
    profile
};

export default connect(null, mapDispatchToProps)(withRouter(SetupProfile));
