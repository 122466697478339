import React, { useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import Logo from "../../assets/brand/logo.svg"
import { logoutUser } from '../../actions/auth';

import {
  CCollapse,
  CNavbar,
  CToggler,
  CNavbarBrand,
  CNav,
  CNavbarNav,
  CNavLink,
  CContainer,
  CButton
} from '@coreui/react';

const ExpertHeader = (props) => {


  let location = useLocation();

  const isRegisterPage = () => {
    if(location.pathname.includes("register")){
      return true
    }
    return false
  }

  const isLoginPage = () => {
    if(location.pathname.includes("login")){
      return true
    }
    return false
  }

  console.log(location)
  const [isOpen, setIsOpen] = useState(false);
  // const { auth: { authenticated } } = store.getState();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <CNavbar light expandable="lg" style={{background: "white", paddingLeft: "6px"}} >
          <CContainer>
            <CNavbarBrand onClick={() => props.history.push("/expert")} style={{fontWeight: "bold", fontSize: 24}} >
              <img src={Logo} width={"200"} className="mr-4" />
            </CNavbarBrand>
            <CToggler inNavbar className={ !isOpen ? "custom-toggler" : "custom-toggler-blue"} onClick={toggle} />

            {props.authenticated ? (
            <CCollapse show={isOpen} navbar>
                <CNavbarNav className="mr-lg-4 ml-auto" >
                  {/* <CNavLink className="mr-3"  className="text-right" href="/">Product Roadmap</CNavLink> */}
                  {/* <CButton size="lg" className="btn-pill d-none d-lg-block text-white mr-4"  color="primary" onClick={() => { props.history.push("/dashboard") }} >Dashboard</CButton> */}
                  <CButton size="lg" className="btn-pill d-none d-lg-block text-white yellowBtn" onClick={() => {props.logoutUser(); props.history.push("/expert") }} >Logout</CButton>
                  {/* <CNavLink className="d-lg-none d-block text-right pointer text-white" onClick={() => { props.history.push("/dashboard") }} >Dashboard</CNavLink> */}
                  <CNavLink className="d-lg-none d-block text-right pointer text-white" onClick={() => {props.logoutUser(); props.history.push("/expert") }} >Logout</CNavLink>
                </CNavbarNav>
            </CCollapse>) : (
              <CCollapse show={isOpen} navbar>
                  <CNavbarNav className="ml-auto" >
                    {!isLoginPage() && <CButton size="lg" className="btn-pill d-none d-lg-block mr-3 yellowText"  onClick={() => { props.history.push("/expert/login") }}  >Login</CButton>}
                    <CNavLink className="d-block d-lg-none text-right pointer " onClick={() => { props.history.push("/expert/login") }}>Login</CNavLink>
                    {!isRegisterPage() && (
                      <div>
                        <CButton size="lg" className="btn-pill d-none d-lg-block yellowBtn" onClick={() => { props.history.push("/register") }} >Register</CButton>
                        <CNavLink className="d-lg-none d-block text-right pointer " onClick={() => { props.history.push("/register") }} >Register</CNavLink>
                      </div>
                    )}
                  </CNavbarNav>
            </CCollapse>
            )}
    </CContainer>
  </CNavbar>

  )
}

const mapDispatchToProps = {
  logoutUser
};

const mapStateToProps = ({ auth }) => ({ authenticated: auth.authenticated });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpertHeader));
