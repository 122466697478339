import React, {useState, useEffect} from "react"
import {
    CModal,
    CModalBody,
    CContainer,
    CForm,
    CAlert,
    CFormGroup,
    CLabel,
    CInput,
    CRow,
    CCol,
    CSelect,
    CTextarea,
    CButton,
    CInvalidFeedback,
    CInputFile
} from "@coreui/react";
import CIcon from '@coreui/icons-react'
import {verifyAccount} from "../actions/UserActions"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import queryString from "query-string";
import verifyAccountImage from "../assets/images/verifyAccount.png"
import { getIntendedUrl, setIntendedUrl } from "../helpers/auth";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const VerifyAccountModal = (props) => {

    const [ error, setError ] = useState(false)
    const [ showSuccess, setShowSuccess ] = useState(false)
    const [ loader, setLoader ] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const sendDataToGTM = useGTMDispatch()

    useEffect(() => {

    }, []);

    const onSubmit = (data) => {
        setLoader(true)
        props.verifyAccount(data).then(() => {
            if(props.client != undefined){
                sendDataToGTM({ event: 'clientRegister', value: true })
                setIntendedUrl("/")
                props.history.push("/dashboard")
            }else{
                setIntendedUrl("/expertSteps")
                props.history.push("/expertSteps")
            }
        }).catch((err) => {
            setError(err)
            setLoader(false)
        })
    };

    return (
        <div className="d-flex align-items-center">
        <CContainer>
            <CModal
                show={props.modal}
                onClose={() => props.toggle(!props.modal)}
                size={"lg"}
                style={{borderRadius: "10px"}}
                >
                <CModalBody className="p-4 pt-2 text-center" >
                    { props.modal && <CIcon name="cil-x-circle" size="xl" className="closeIcon pointer" onClick={() => props.toggle(!props.modal)} /> }
                    <p className="heading mb-4 bold" >{ showSuccess ? "Check your email" : "Please confirm your email"} </p>
                    <div className=" p mb-4" >
                        {props?.msg && <p className="lead mb-1" style={{color: "red"}} >{props.msg}</p>}
                        {!props?.msg && <p className="lead mb-1" >We just sent you an email to verify your email address.</p>}
                        <p className="lead mb-0" >Please enter the verification code to continue, it may take a few minutes to arrive, Also, check your spam folder in case you don’t see the email.</p>
                        <div>
                            <img className="img-fluid" src={verifyAccountImage}  />
                        </div>
                    </div>

                    { !showSuccess ? (<CForm onSubmit={handleSubmit(onSubmit)} >
                    { error && <CAlert closeButton color="danger">
                      {error}
                    </CAlert>}
                        <CRow>
                            <CCol xs="12" >
                                <CFormGroup className="pr-1">
                                    {/* <CLabel htmlFor="exampleInputName2" className="pr-1">Email Address</CLabel> */}
                                    <CInput className="customInput" placeholder="Verification Code" name="code" invalid={errors.code && true}
                                    innerRef={register({ required: true
                                    })} />
                                    {errors.code && <CInvalidFeedback>{"Can we get your code?"}</CInvalidFeedback>}
                                </CFormGroup>
                            </CCol>

                        </CRow>

                        <CRow className="mt-4" >
                            <CCol className="text-center">
                                <CButton color="primary" className="btn200 btn-pill" type="submit" disabled={loader} >{ loader ? "Verifying..." :  "Continue" }</CButton>
                            </CCol>
                        </CRow>
                    </CForm>) : (
                        <CRow className="mt-4" >
                            <CCol className="text-center" >
                                <CButton color="primary" className="btn200 btn-pill" onClick={() => props.toggle(!props.modal)} >Done</CButton>
                            </CCol>
                        </CRow>
                    )}
                </CModalBody>
            </CModal>
            </CContainer>
        </div>
        )
}

const mapDispatchToProps = {
    verifyAccount,
};
export default connect(null, mapDispatchToProps)(withRouter(VerifyAccountModal));
