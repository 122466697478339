
export const getExpertMeta = (query) => dispatch => {
    return  window.axios.get('/api/expertMeta?'+query).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updateExpertCat = (data) => dispatch => {
    return  window.axios.post('/api/updateExpertCat', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updateSelectedSkill = (data) => dispatch => {
    return  window.axios.post('/api/updateSelectedSkill', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const updatePref = (data) => dispatch => {
    return  window.axios.post('/api/updatePref', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data.message);
    });
}

export const callScheduled = (data) => dispatch => {
    return  window.axios.post('/api/callScheduled', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}

export const expertCallScheduled = (data) => dispatch => {
    return  window.axios.post('/api/expert/callScheduled', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}

export const updateClientDetails = (data) => dispatch => {
    return  window.axios.post('/api/updateClientDetails', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}

export const getExpertise = () => dispatch => {
    return  window.axios.get('/api/getExpertise').then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}

export const openRequests = () => dispatch => {
    return  window.axios.get('/api/openRequests').then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}

export const saveClientQuery = (data) => dispatch => {
    return  window.axios.post('/api/saveClientQuery', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch((error) => {
        return Promise.reject(error.response.data);
    });
}