import React, {useState, useEffect, useRef} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    CForm,
    CAlert,
    CFormGroup,
    CLabel,
    CInput,
    CRow,
    CCol,
    CSelect,
    CTextarea,
    CButton,
    CInvalidFeedback,
    CCard,
    CCardBody
} from "@coreui/react";
import 'react-phone-input-2/lib/style.css'
import { TimezoneSelect, clientTz } from 'timezone-select-js';
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorText from "../ErrorText";
import { STORE, TOAST } from "../../helpers/CommonHelper";
import { updatePref } from "../../actions/ExpertActions";
import moment from "moment-timezone";
import store from "../../store";
import { updateExpertStatus } from "../../actions/UserActions";

const Preferences = props => {

    const {preferencesMeta, selectedPref, persistSelectecPref} = props
    const [error, setError] = useState("")
    const [updateLoader, setUpdateLoader] = useState(false)
    const {register, handleSubmit, errors, getValues, control,watch} = useForm();
    const [avail, setAvail] = useState(selectedPref.availability ? moment(selectedPref.availability).toDate() : new Date())

    const onSubmit = (data) => {
        data["tz"] = data.tz.value
        // if(selectedTimezone){
        //     data["tz"] = selectedTimezone.value
        // }
        data["availability"] = avail
        setUpdateLoader(true)
        STORE.dispatch(updatePref(data)).then(() => {
            store.dispatch(updateExpertStatus({status: "UNVERIFIED"}))
            props.updateStep(props.step+1)
            // props.history.push("/dashboard")
            // setUpdateLoader(false)
        }).catch((err) => {
            TOAST.error(err)
            setUpdateLoader(false)
        })
    }

    const handleBackButton = () => {
        var vals  = getValues()
        vals["availability"] = avail
        vals["tz"] = vals.tz ? (vals.tz.value ? vals.tz.value : vals.tz) : null 
        persistSelectecPref(vals)
        props.updateStep(props.step-1)
    }

    return (
        <>
            <div>
                <div className="d-flex align-items-center mb-3" >
                    <div className="mr-3 yellowBg" style={{width: "60px", height: "60px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                        <p className="mb-0 heading bold" style={{color: "#fff"}} >3</p>
                    </div>
                    <p className="heading whiteText">Set up your preferences</p>
                </div>
            <CCard className="boxShadow">
                <CCardBody>
                <CRow className="mb-5">
                    <CCol>
                        <CRow>
                            <CCol xs="12">
                                <p className="heading mb-0 bold">Preferences </p>
                                <p className="subHeading mb-4" >You’ll also be able to update your preferences in your profile at any time.</p>
                            </CCol>
                                <CCol>
                                    {<CForm autoComplete="off" >
                                        {error && <CAlert closeButton color="danger">
                                            {error}
                                        </CAlert>}
                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Current time zone</CLabel>
                                                    <Controller
                                                        render={(
                                                            { onChange, onBlur, value, name, ref }
                                                          ) => (
                                                            <TimezoneSelect
                                                                onBlur={onBlur}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                          )}
                                                        name="tz"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={selectedPref.tz ? selectedPref.tz : clientTz()}
                                                    />
                                                        
                                                    {errors.tz && <ErrorText>This field is required</ErrorText>}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Interest in full-time, par-time projects or positions</CLabel>
                                                    <CSelect defaultValue={selectedPref.interest} invalid={errors.interest && true}  className="cutomSelect d-block" name="interest" innerRef={register({required: true})} style={{maxWidth: "100%"}} >
                                                        <option value=""  >Select</option>
                                                        {Object.keys(preferencesMeta.interests).map((key) => (
                                                            <option value={key}> {preferencesMeta.interests[key]}</option>
                                                        ))}
                                                    </CSelect>
                                                    {errors.interest && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs="12" md="6">
                                            <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Travel willingness</CLabel>
                                                    <CSelect defaultValue={selectedPref.willing_travel} invalid={errors.willing_travel && true}  className="cutomSelect d-block" name="willing_travel" innerRef={register({required: true})}  style={{maxWidth: "100%"}} >
                                                        <option value=""  >Select</option>
                                                        {Object.keys(preferencesMeta.travel).map((key) => (
                                                            <option value={key}> {preferencesMeta.travel[key]}</option>
                                                        ))}
                                                    </CSelect>
                                                    {errors.willing_travel && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel className="pr-1 bold">Available from</CLabel>
                                                    {/* <CSelect invalid={errors.availability && true}  className="cutomSelect d-block" name="availability" innerRef={register({required: true})} style={{maxWidth: "100%"}} >
                                                        <option  >dd/mm/yyyy</option>
                                                    </CSelect> */}
                                                    {/* <Controller
                                                        as={( */}
                                                            <DatePicker selected={avail} onChange={(date) => setAvail(date)} className="formControl cutomSelect" />
                                                   
                                                    {!avail && <ErrorText>This field is required</ErrorText>}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                       
                                        <CRow>
                                            {/* <CCol xs="12" md="6">
                                            <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Max work day</CLabel>
                                                    <CSelect defaultValue={selectedPref.max_work_day} invalid={errors.max_work_day && true}  name="max_work_day" innerRef={register({required: true})} className="cutomSelect d-block" style={{maxWidth: "100%"}} >
                                                    <option value=""  >Select</option>
                                                        {Object.keys(preferencesMeta.maxWorkDay).map((key) => (
                                                            <option value={key}> {preferencesMeta.maxWorkDay[key]}</option>
                                                        ))}
                                                    </CSelect>
                                                    {errors.max_work_day && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol> */}
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">How did you learn about us?</CLabel>
                                                    <CSelect defaultValue={selectedPref.know_us} invalid={errors.know_us && true}  name="know_us" innerRef={register({required: true})} className="cutomSelect d-block" style={{maxWidth: "100%"}} >
                                                    <option value=""  >Select</option>

                                                        {Object.keys(preferencesMeta.howKnow).map((key) => (
                                                            <option value={key}> {preferencesMeta.howKnow[key]}</option>
                                                        ))}
                                                    </CSelect>
                                                    {errors.know_us && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Reference Daily Rate </CLabel>
                                                    <div className="d-flex align-items-center" >
                                                        <CInput type="number" className="mr-4 customInput bold" innerRef={register({required: true})} invalid={errors.rate && true} defaultValue={selectedPref.rate} name="rate" ></CInput>
                                                        {errors.rate && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                        <CSelect defaultValue={selectedPref.currency} invalid={errors.currency && true}  name="currency" innerRef={register({required: true})} className="cutomSelect"  >
                                                        <option value=""  >Select</option>

                                                            {preferencesMeta.currency.map((val) => (
                                                                <option value={val}> {val}</option>
                                                            ))}
                                                        </CSelect>
                                                        {errors.currency && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                                    </div>
                                                    
                                                    {/* {errors.first_name && <CInvalidFeedback>First name is required</CInvalidFeedback>} */}
                                                </CFormGroup>

                                                <div>
                                                    <p className="lead" >
                                                        Excluding VAT and travel expenses, you’ll also be asked to verify your rate for each project.
                                                    </p>
                                                </div>
                                            </CCol>
                                            <CCol xs="12" md="6">
                                                <CFormGroup className="pr-1">
                                                    <CLabel  className="pr-1 bold">Please tell us about yourself or your company.</CLabel>
                                                    <CTextarea defaultValue={selectedPref.comments} name="comments" innerRef={register()} style={{minHeight: "200px"}} ></CTextarea>
                                                    {/* {errors.comments && <CInvalidFeedback>This field is required</CInvalidFeedback>} */}
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow className="mt-4">
                                            <CCol className="d-flex justify-content-between align-items-center">
                                                <CButton color="secondary" className="btn100 btn-pill bold" onClick={handleBackButton}>Back</CButton>
                                                <CButton color="primary" className="btn100 btn-pill bold" onClick={handleSubmit(onSubmit)} disabled={updateLoader}>{updateLoader ? "Saving" : "Submit for review"}</CButton>
                                            </CCol>
                                        </CRow>

                                    </CForm>}
                                </CCol>
                        </CRow>
                    
                    </CCol>
                </CRow>
                </CCardBody>
            </CCard>
            </div>
        </>
    );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withRouter(Preferences));
