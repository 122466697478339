import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CRow,
    CCol,
    CButton,
    CCard,
    CCardBody,
    CForm,
    CTextarea,
    CInput,
    CInvalidFeedback,
    CFormGroup,
    CLabel,
    CSelect
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import {useForm} from 'react-hook-form';
import { STORE } from "../../../../helpers/CommonHelper";
import { updateClientDetails } from "../../../../actions/ExpertActions";
import { useSelector } from "react-redux";

const ClientChallenges = props => {
    const {preferencesMeta} = props
    const {register, handleSubmit, errors, setError, watch} = useForm()
    const [error, setDefaultError] = useState(false)
    const [data, setData] = useState(props.data)
    const watchAllFields = watch()
    const showCompanyStep = useSelector(state => state.client.showCompany)

    const onSubmit = (data) => {
        // STORE.dispatch(updateClientDetails(data)).then((resp) => {
            props.persistData(data)
            props.updateStep(props.step+1)
        //})
    }

    return <div>
            <div className="d-flex align-items-center mb-3" >
                <div className="mr-3 yellowBg" style={{width: "70px", height: "70px", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                    <p className="mb-0 bold heading" style={{color: "#fff"}} >2</p>
                </div>
                <p className="heading">Your Needs</p>
            </div>
        <CCard className="boxShadow">
            <CCardBody>
                <div>
                    <CForm onSubmit={handleSubmit(onSubmit)}>
                        {error && <CAlert color="danger">
                            {error}
                        </CAlert>}

                        <p className="heading bold mb-4" >Product and Challenge - What is your current challenge?</p>
                        <div className="mb-4">
                            <p className="mb-2 bold lead" >Please briefly describe your product (please do not share any secret or proprietary information at this stage).</p>
                            <CTextarea
                            placeholder="Product  - Briefly describe your product"
                            name="product_desc" innerRef={register({required: true})} defaultValue={data.product_desc} rows={"4"} ></CTextarea>
                            {/* {errors.prod_desc &&
                            <CInvalidFeedback>{errors.username.message}</CInvalidFeedback>} */}
                        </div>

                        <div className="mb-4">
                            <p className="mb-2 bold lead" >Please describe the issues or concerns you are facing</p>
                            <CTextarea
                            placeholder="Issues & Expertise Needed – What kind of expertise would be helpful for you? What skills would be needed? Is any specific experience required?"
                            name="product_issues" innerRef={register({required: true})} rows={"6"} defaultValue={data.product_issues} ></CTextarea>
                        </div>

                        <div className="mb-4">
                            <p className="mb-2 bold lead" >Job Requirements - Is this a one-time project or an ongoing operational requirement? What is the work that you need the Expert to do?</p>
                            <CTextarea
                            placeholder="What is the work that you need the Expert to do? If you have a job description, please paste it here."
                            name="job_req" innerRef={register({required: true})} rows={"6"} defaultValue={data.job_req} ></CTextarea>
                        </div>
                        
                        <CRow>
                            <CCol>
                                <div>
                                    <p className="lead bold" >Title for your request</p>
                                    <div>
                                        <div className="mb-4">
                                            <CInput defaultValue={data.title} className="customInput" invalid={errors.title && true} name="title" type="text"
                                            placeholder="Write a descriptive title for your project or role request" innerRef={register({required: true})}/>
                                            {errors.title &&
                                            <CInvalidFeedback>Request title is required</CInvalidFeedback>}
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                            <CCol>
                                <CFormGroup className="pr-1">
                                    <p  className="pr-1 bold lead">Expert Location</p>
                                    <CSelect defaultValue={data.expert_location} invalid={errors.expert_location && true}  className="cutomSelect d-block" name="expert_location" innerRef={register({required: true})} style={{maxWidth: "100%"}} >
                                        <option value=""  >Select</option>
                                        {Object.keys(preferencesMeta.expertLocation).map((key) => (
                                            <option value={key}> {preferencesMeta.expertLocation[key]}</option>
                                        ))}
                                    </CSelect>
                                    {errors.expert_location && <CInvalidFeedback>This field is required</CInvalidFeedback>}
                                </CFormGroup>
                            </CCol>
                        </CRow>
                        <CRow className="mt-5">
                            <CCol className="d-flex align-items-center justify-content-end">
                                <CButton
                                    size="lg"
                                    onClick={() => {
                                        props.persistData(watchAllFields);
                                        if(!showCompanyStep){
                                            props.updateStep(props.step-2)
                                        }else{
                                            props.updateStep(props.step-1)
                                        }
                                    }}
                                    className="btn200 btn-pill btn-secondary d-flex justify-content-around align-items-center mr-5" >
                                    <CIcon size="xl"name="cil-arrow-left"/>
                                    <p className="mb-0 lead" >Back</p>
                                </CButton>
                                <CButton
                                    size="lg"
                                    type="submit"
                                    // onClick={() => props.updateStep(props.step+1)}
                                    className="btn200 btn-pill btn-primary d-flex justify-content-around align-items-center mr-4" >
                                    <p className="mb-0 lead" >Next Step</p>
                                    <CIcon size="xl"name="cil-arrow-right"/>
                                </CButton>
                            </CCol>
                        </CRow>

                    </CForm>
                </div>

                
            </CCardBody>
        </CCard>
        </div>
};

export default (withRouter(ClientChallenges));
