import {checkTokenExists, setToken, setUserBasicInfo} from '../helpers/auth';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';

const fetchUser = () => {
    return window.axios.get('/api/get/user/detail')
        .then(({data: {data}}) => Promise.resolve(data))
        .catch(error => Promise.reject(error));
};

export const register = req => dispatch => {
    return window.axios.post('/api/register', req).then((data) => {
        return Promise.resolve(data.data);
    }).catch(error => {
        return Promise.reject(error.response.data);
    });
};

export const login = (data) => dispatch => {
    return window.axios.post('/api/login', data).then(({data}) => {
        setToken(data.meta.token);
        dispatch(setAuthenticated(true));
        dispatch(setUserData(data.data));
        return Promise.resolve(data);
    }).catch(error => {
        if (error.response.status === 301) {
            // TRIGGER VERIFICATION MODAL
            return Promise.reject({redirect: true});
        } else {
            return Promise.reject(error.response.data.message);
        }
    });
}

export const socialLogin = (data) => dispatch => {
    return window.axios.post('/api/socialLogin', data).then(({data}) => {
        setToken(data.meta.token);
        dispatch(setAuthenticated(true));
        dispatch(setUserData(data.data));
        return Promise.resolve(data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const setPassword = (data) => dispatch => {
    return window.axios.post('/api/setPassword', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch(error => {
        return Promise.reject(error.response.data);
    });
}

export const registerBeta = (data) => dispatch => {
    return window.axios.post('/api/joinBeta', data).then(({data}) => {
        return Promise.resolve(data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}


export const setUserData = user => ({
    type: SET_USER_DATA,
    user
});

export const setAuthenticated = authenticated => ({
    type: SET_AUTHENTICATED,
    authenticated
});

export const clearAuth = () => dispatch => {
    setToken(null);
    dispatch(setUserData(null));
    dispatch(setAuthenticated(false));
};

export const logoutUser = cb => dispatch => {
    return window.axios.post('/api/logout')
        .then(response => {
            pusher.disconnect();
            dispatch(clearAuth());
        })
        .catch(anyError => {
            dispatch(clearAuth());
        });
};

export const initAuthFromExistingToken = (cb) => dispatch => {
    checkTokenExists().then(token => {
        setToken(token);
        fetchUser().then(data => {
            dispatch(setUserData(data));
            dispatch(setAuthenticated(true));
            cb();
        }).catch(anyError => {
            dispatch(clearAuth());
            cb();
        });
    }).catch(anyError => {
        dispatch(clearAuth());
        cb();
    });
};
