import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from '../pages/Auth/Login';
import {initAuthFromExistingToken} from '../actions/auth';
import {setLoading} from '../actions/loading';
import {connect} from 'react-redux';
import GuestRoute from './GuestRoute';
import Page404 from '../pages/Page404';
import AuthRoute from './AuthRoute';
import Home from '../pages/Main/Home';
import NonLoginRoute from './NonLoginRoute';
import RegisterToggle from '../pages/Auth/RegisterToggle';
import ResetPassword from '../pages/Auth/ResetPassword';
import ExpertJoinIndex from '../pages/Main/ExpertJoin/ExpertJoinIndex';
import ExpertJoinSteps from '../pages/Main/ExpertJoin/ExpertJoinSteps';
import Dashboard from '../pages/Main/Dashboard';
import ClientHome from '../pages/Main/ClientHome';
import ClientRegister from '../pages/Main/Client/ClientOnboard/ClientRegister';
import ClientOnboard from '../pages/Main/Client/ClientOnboard/ClientOnboard';
import ClientJoinSteps from '../pages/Main/Client/ClientOnboard/ClientJoinSteps';
import FindExperts from '../pages/Main/Client/ClientOnboard/FindExpert/FindExperts';
import ExpertNonLogin from './Experts/ExpertNonLogin';
import ExpertAuth from './Experts/ExpertAuth';
import ExpertGuest from './Experts/ExpertGuest';
import ExpertLogin from '../pages/Auth/Experts/ExpertLogin';

class App extends Component {
    componentDidMount() {
        this.props.initAuthFromExistingToken(() => this.props.setLoading(false));
    }

    render() {
        if (this.props.loading) {
            return (
                <span>Loading...</span>
            );
        }
        return (
            
            <Router>
                <Switch>
                    <NonLoginRoute exact path="/" component={ClientHome}/>
                    <NonLoginRoute exact path="/client/onboard" component={ClientOnboard}/>
                    <NonLoginRoute exact path="/client/about" component={ClientJoinSteps}/>
                    <GuestRoute exact path="/client/register" component={ClientRegister}/>
                    <NonLoginRoute exact path="/client/findExperts" component={FindExperts}/>
                    <GuestRoute exact path="/login" component={Login}/>
                    <GuestRoute exact path="/passwordReset/:id" component={ResetPassword}/>
                    <AuthRoute exact path="/dashboard" component={Dashboard}/>
                    <ExpertNonLogin exact path="/expert" component={Home}/>
                    <ExpertGuest exact path="/expert/login" component={ExpertLogin}/>
                    <ExpertAuth exact path="/expertJoin" component={ExpertJoinIndex}/>
                    <ExpertAuth exact path="/expertSteps" component={ExpertJoinSteps}/>
                    <ExpertGuest exact path="/register" component={RegisterToggle}/>
                    <Route component={Page404}/>
                </Switch>
            </Router>
        );
    }
}

const mapDispatchToProps = {
    setLoading,
    initAuthFromExistingToken
};

const mapStateToProps = ({loading}) => ({loading});

export default connect(mapStateToProps, mapDispatchToProps)(App);
