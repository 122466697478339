import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    CCol,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CCard,
    CCardBody,
    CTabs,
    CJumbotron,
    CContainer,
    CModal,
    CModalBody,
    CButton
} from "@coreui/react";
import ClientProduct from "./ClientProduct";
import ClientCompany from "./ClientCompany";
import ClientChallenges from "./ClientChallenges";
import Loader from "../../../../components/Loader";
import { STORE } from "../../../../helpers/CommonHelper";
import { getExpertMeta, updateExpertCat } from "../../../../actions/ExpertActions";
import ExpertCall from "../../../../components/ExpertJoin/ExpertCall";
import CallConfirmed from "../../../../components/ExpertJoin/CallConfirmed";

const ClientJoinSteps = props => {

    const [currentStep, setCurrentstep] = useState(0)
    const [expertMeta, setExpertMeta] = useState([])
    const [preferencesMeta, setPreferencesMeta] = useState([])
    const [loader, setLoader] = useState(true)
    const [calendlyEvent, setCalendlyEvent] = useState()
    const [reSched, setResched] = useState(false)
    const [selectedCats, setSelectedCats] = useState({
        ids: [],
        titles: [],
        combined: []
    })
    const [profile, setProfile] = useState()
    const [companyData, setCompanyData] = useState({
        company_country: null,
        company_website: null,
        bSizes: [],
        company_state: null
    })
    const [expertiseData, setExpertiseData] = useState({})

    useEffect(() => {
        STORE.dispatch(getExpertMeta("type=client")).then((resp) => {

            if(resp.hasRequests){
                STORE.dispatch({
                    type: "SET_SHOW_COMPANY",
                    payload: false
                })
            }
            
            if(resp?.redirect){
                if(resp.redirect == "CALL_SCHEDULE"){
                    updateStep(3)
                }else if(resp.redirect == "CALL_CONFIRMED"){
                    setCalendlyEvent(resp.calendly_event)
                    updateStep(4)
                }else{
                     props.history.push("/dashboard")
                }
            }else{
                // SORT CATEGORIES
                var orderedCats = []
                var order = ["Product Stages", "Product Types"]
                order.map((cat) => {
                    resp.categories && resp.categories.map((dbCat) => {
                        if(dbCat.title == cat){
                            orderedCats.push(dbCat)
                        }
                    })
                })
                setExpertMeta(orderedCats)
                setPreferencesMeta(resp.preferences)
                setProfile(resp.profile)
                // setSelectedCats(resp.selectedCats)
                // setCompanyData(resp.companyData)
            }
            setLoader(false)
        })
    }, []);

    const updateStep = (step) => {
        setCurrentstep(step)
    }

    const handleCatClick = (checked, catId, title) => {
        var expertiseDataCopy = {...expertiseData}
        if(checked){
            expertiseDataCopy[catId] = {
                catId: catId,
                title: title
            }
        }else{
            delete expertiseDataCopy[catId]
        }
        setExpertiseData(expertiseDataCopy)
        // STORE.dispatch(updateExpertCat(reqData))
    }

    const persistCompanyData = (data) => {
        setCompanyData({...companyData, ...data})
    }

    const renderStep = () => {
        if(currentStep == 0){
            return <ClientProduct step={currentStep} data={expertMeta} updateStep={updateStep} handleCatClick={handleCatClick} selectedCats={selectedCats} setSelectedCats={setSelectedCats} />
        }
        else if(currentStep == 1){
            return <ClientCompany updateStep={updateStep} step={currentStep} data={companyData} persistData={persistCompanyData} />
        }else if (currentStep == 2){
            return <ClientChallenges preferencesMeta={preferencesMeta} updateStep={updateStep} step={currentStep} data={companyData} persistData={persistCompanyData}  />
        }else if (currentStep == 3){
            return <ExpertCall
                reqData={{
                    expertiseData,
                    companyData
                }}
                data={profile}
                client={true} setCalendlyEvent={setCalendlyEvent} reSched={reSched} calendlyEvent={calendlyEvent} updateStep={updateStep} step={currentStep} />
            // return <ScheduleCall reSched={false} updateStep={updateStep} step={currentStep}   />
        }else if (currentStep == 4){
            return <CallConfirmed client={true} updateStep={updateStep} step={currentStep} />
        }
    }

    return (
        <>
            <div className="mt-5" >
            {loader ? <Loader/> : renderStep()}
            </div>
        </>
    );
};

export default (withRouter(ClientJoinSteps));
